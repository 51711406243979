<!--  -->
<template>
    <div class="Server">
        <div class="title">
            <img src="../../assets/img/list.png"/>
            <h2>我的服务列表</h2>
        </div>

        <div class="bottom">
            <div class="caption">
                <ul class="flex">
                    <li>地点</li>
                    <li class="p-l-30">服务</li>
                    <li>交付状态</li>
                </ul>
            </div>
            <el-divider></el-divider>
            <div class="project">
                    <ul class="flex" v-for="(item) in ProjectList" :key="item.id">
                        <li class="color-f">{{item.place}}{{item.id}}</li>
                        <li class="color-f">{{item.serve}}</li>
                        <li :class="[item.finish?'color-1':'color-3']">{{item.finish?'已完结':'未完结'}}</li>
                    </ul>
            </div>
        </div>
    </div>
</template>


<script>
    export default{
        name:'MyServer',
        data(){
            return{
                ProjectList:[
                    {id:12,place:'半亩花园',serve:'锦鲤养护',finish:false},
                    {id:36,place:'半亩花园',serve:'土壤检测安装',finish:true},
                    {id:56,place:'半亩花园',serve:'名木养护',finish:false},
                    {id:76,place:'半亩花园',serve:'土壤检测养护',finish:true},
                    {id:88,place:'半亩花园',serve:'名木养护',finish:false},
                ]
            }
        },

    }
</script>

<style lang='scss' scoped>
    .Server{
        width: 32.625rem;
        height: 20rem;
        background: url(../../assets/img/Server.png) no-repeat;
        box-sizing: border-box;
        padding: 1.25rem 1.5625rem 1.5625rem 1.5625rem;
        .title{
            width: 16.375rem;
            height: 1.5rem;
            margin-bottom: 1.9375rem;
            img{
                height: 1.125rem;
                width: 1.125rem;
            }
            h2{
                font-size: 1.5rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
                margin-left:.4375rem;
                display: inline-block;
            }
        }

        .bottom{
            width:29.125rem ;
            height: 13.125rem;
            .caption{
                width: 29.125rem;
                box-sizing: border-box;
                padding: 0 1.0625rem 0 3rem;
                margin-bottom: .5625rem;
                ul{
                    li{
                        font-size: 1.25rem;
                        color: #ffffff;
                        font-weight: 400;
                    }
                }
            }
            .el-divider--horizontal{
                margin: 0;
            }
            .project{
                height:10.4375rem ;
                width:29.125rem ;
                box-sizing: border-box;
                padding: 0  1.375rem 0  1.0625rem;
               ul{
                    li{
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 36px;
                    } 
               }
            }
        }
}
</style>