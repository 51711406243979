<template>
    <div class='bigScreen' :style="{'background': 'url(' + require('@/assets/img/bg-1.png') + ') 0 0 no-repeat'}">
                <!-- 头部 -->
                <!-- <MyScaleBox> -->
           
               <div class="Screen">
                    <ScreenAdapter>
                         <div class="header">
                             <div class="header-right width-415">
                                <!-- <img src="../../assets/img/Biglogo.png" @click="handleback"/>
                                <MyNowDate/> -->
                            </div> 
                            <div class="select-btn">
                                <el-select 
                                    v-model="value" 
                                    placeholder="请选择"
                                    popper-class="select"
                                    :popper-append-to-body="false"
                                    @change="topages(value)"
                                >
                                    <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        
                        </div>  
                        <!-- 大屏主要内容 -->
                        <div class="content"> 
                            <div class="con-left">
                                <MyRtMessage/>
                                <MyServer/>
                                <MyGood/>
                            </div>
                            <div class="con-center flex-c">
                                <MyRtData/>
                                <MyWarning/>
                            </div>
                            <div class="con-right flex-c">
                                <MyHot/>
                                <MyShop/>
                            </div> 
                        </div>
        <!-- </MyScaleBox> -->
                    </ScreenAdapter>
                </div>
            
        
    </div>
    </template>
    
    <script>
    export default {
        data(){
            return{
            options: [{
              value: '选项1',
              label: '超级管理员'
            }, {
              value: '选项2',
              label: '城市运营商'
            }, {
              value: '选项3',
              label: '城市服务商'
            }],
            value: '',
            }
        },
    
        methods:{
            handleback(){
                this.$router.push({
                    path:'/index'
                })
            },
            topages(value){
               if(value=='选项1'){
                    const str='1';
                    localStorage.setItem('big', str);
                    this.$router.push({path:'/root'},()=>{}, ()=>{});
               }
               else if(value=='选项2'){
                    this.$router.push({path:'/LargeScreen'},()=>{}, ()=>{});
                    const str='2';
                    this.$router.push({path:'/LargeScreen'},()=>{}, ()=>{});
               }
            }
        }
    }
    </script>
    
    <style lang='scss' scoped>
            // @media screen and  (orientation:  portrait){
            //     .Screen{
            //     transform-origin:center !important;
            //     transform: rotateZ(90deg)  !important;
            //     }
            // }
            .bigScreen{ 
                width: 100vw;
                height: 100vh;
                background-size:cover;
                .Screen{
                    width: 100vw;
                    height: 100vh;
                .header{
                    height: 4rem;
                    width: 120rem;
                    display: flex;
                    justify-content: flex-end;
                    background:url(../../assets/img/title.png)  no-repeat;
                    box-sizing: border-box;
                    padding: .625rem 2.5rem 1.8125rem 2.5625rem;
                    .header-right{
                        display: flex;
                        justify-content: space-between;
                    }
                    /deep/.select-btn{
                        box-sizing: border-box;
                        padding-top: .625rem;
                        .el-input__inner{
                            width: 12.4375rem;
                            height: 2.25rem;
                            font-size: 17px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FFFFFF;
                            background-color: #0E1F31;
                            border: 1px solid #6DA0D4;
                            opacity: 0.8;
                            border-radius: 6px;
                        }
                    }
                }
    
                .content{
                width: 120rem;
                height: 57.875rem;
                box-sizing: border-box;
                padding: 1.6875rem 2.125rem 1.8125rem 2.1875rem;
                display: flex;
                justify-content: space-between;
                    .con-left{
                    width: 32.625rem;
                    height: 57.875rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    }        
                    .con-center{
                        width: 41.4375rem;
                        height: 57.875rem;    
                    }
                    .con-right{
                        width: 36.375rem;
                        height: 57.875rem;
                    }
                }
            }
        }
            //select选择器覆盖样式
            /deep/ .el-select-dropdown {
                background-color: #0E1F31;
                border: 1px solid #6DA0D4;
                opacity: 0.8;
                position: absolute !important;
                top:24px !important;
                left: 0 !important;
            
            }
            /deep/ .el-select-dropdown__item {
                background-color: #0E1F31;
                color: #fff;
            }
            /deep/ .el-select-dropdown__item:hover {
            color: #fff;
            background:  #35FBFF;
            }

    </style>