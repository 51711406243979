<!--  -->
<template>
<div class='Shop'>
    <div class="top">
        <img src="../../assets/img/serve-icon.png"/>
        <h2>本月服务订购TOP3</h2>
    </div>
    <div class="shop-center">
        <ul 
            v-for="(item) in shopList" 
            :key="item.id" 
            :class="[item.id==1?'m-t-12':'',item.id==2?'m-t-65':'',item.id==3?'m-t-90':'']"
            >
            <li :class="[item.id==1?'color-1':'',item.id==2?'color-2':'',item.id==3?'color-3':'']">{{item.grade}}</li>
            <li :class="[item.id==1?'bg-1':'',item.id==2?'bg-2':'',item.id==3?'bg-3 p-3':'p-common']">{{item.name}}</li>
        </ul>
    </div>
    <div class="bottom">
        <div class="title">
            <img src="../../assets/img/sales.png"/>
            <h3>本月商场销量TOP3</h3>
        </div>
        <div class="sort">
            <div class="header">
                <ul>
                    <li>服务</li>
                    <li>数量</li>
                    <li>成交额(元)</li>
                </ul>
            </div>
            <div class="ranking">
                <ul 
                class="flex"
                v-for="(item) in TopThree"
                :key="item.id"
                >
                    <li 
                    class="iconfont icon-huangguan"
                    :class="[item.id==1?'color-gold':'',item.id==2?'color-silver':'',item.id==3?'color-copper':'']"
                    ></li>
                    <li class="m-lr width-81 color-f">{{item.name}}</li>
                    <li class="m-r-125 width-50 color-f">{{item.num}}</li>
                    <li class="width-73 color-f">{{item.turnover}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default{
    name:'MyShop',
    data(){
        return{
            shopList:[
                {id:2,name:'古树养护',grade:'No.2'},
                {id:1,name:'智能驱蚊',grade:'No.1'},
                {id:3,name:'红火蚁防治',grade:'No.3'}
            ],
            TopThree:[
                {id:1,name:'大金空调',num:15,turnover:500000},
                {id:2,name:'花园设计',num:3,turnover:450000},
                {id:3,name:'大师漆',num:2210,turnover:170000},
            ]

        }
    }
}
</script>


<style lang='scss' scoped>
.Shop{
    width: 36.375rem;
    height: 36.6875rem;
    background: url(../../assets/img/shop.png) no-repeat;
    box-sizing: border-box;
    padding:1.375rem 0 35px 1.5rem;
    .top{
        margin-bottom:.8125rem;
        img{
            height: 1.1875rem;
            width: 1.0625rem;
            margin-right:.625rem;
        }
        h2{
            display: inline-block;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
        }
    }
    .shop-center{
        height: 18.125rem;
        width: 33.3125rem;
        background: url(../../assets/img/base.png) no-repeat top center;
        margin-bottom:.8125rem;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding:0 5rem 0 4.8125rem;
        ul{
            li{
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                text-align: center;
                &:nth-child(1){
                    margin-bottom:.625rem;
                }
                &:nth-child(2){
                    width: 4.8rem;
                    height: 5rem;
                    color:#FFFFFF;
                    display: table-cell;
                    vertical-align: middle;
                }
            }
        }
    }

    .bottom{
        .title{
            margin-bottom:.875rem;
            img{
                height: 1.1875rem;
                width: 1.1875rem;
                margin-right:.75rem;
            }
            h3{
                display: inline-block;
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
            }
        }
        .sort{
           width: 32.5625rem; 
           height: 8.8125rem;
           .header{
                box-sizing: border-box;
                padding-left:5.625rem;
                ul{
                    display: flex;
                    justify-content: space-between;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-bottom: 1.5rem;
                }
           }
           .ranking{
            width:32.5625rem ;
            height: 6.0625rem;
            box-sizing: border-box;
            padding:0 .8125rem 0 1.3125rem;
            ul{
                margin-bottom:1.125rem ;
                display: flex;
                li{
                    font-size: 1.25rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    text-align: center;
                    height: 1.25rem;
                }
            }
           }
        }
    }
}
</style>