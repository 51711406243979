<!--  -->
<template>
<div class='Good'>
    <div class="title">
        <img src="../../assets/img/server-icon.png"/>
        <h2>本月优秀服务商TOP3</h2>
    </div>
    <main class="flex">
        <ul 
        class="width-81 height-186"
        v-for="(item) in SeverList"
        :key="item.id"
        :class="[item.id==1?'':'']"
        >
            <li class="font-20" 
                :class="[item.id==2?'color-2 m-t-26':'',item.id==1?'color-1':'',item.id==3?'color-3 m-t-26':'']"
                 >{{item.rank}}</li>
            <li class="font-20 color-f"
                :class="[item.id==1?'bg-1':'',item.id==2?'bg-2':'',item.id==3?'bg-3':'']"
            >
                <p class="width-40 height-40">{{item.name}}</p>
            </li>
            <li class="font-16 color-f">{{item.money}}元</li>
            <li class="font-16 color-f">{{item.grand}}分</li>
        </ul>
    </main>
</div>
</template>


<script>
export default{
    name:'MyGood',
    data(){
        return{
            SeverList:[
                {id:2,name:'蜻蜓万物',rank:'No.2',money:1209812,grand:99,background:'../../assets/img/蜻蜓万物2'},
                {id:1,name:'蜻蜓万物',rank:'No.1',money:1209812,grand:99,background:'../../assets/img/蜻蜓万物1'},
                {id:3,name:'蜻蜓万物',rank:'No.3',money:1209812,grand:99,background:'../../assets/img/蜻蜓万物3'},
            ]
        }
    }
}
</script>

<style lang='scss' scoped>
.Good{
    height: 18.5rem;
    width: 32.625rem;
    background: url(../../assets/img/good.png) no-repeat;
    box-sizing: border-box;
    padding:1.3125rem 3.4375rem 2.5rem 1.5625rem;
    .title{
        width: 16.375rem;
        height: 1.5rem;
        margin-bottom: 1.6875rem;
        h2{
            font-size: 1.5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            margin-left:.4375rem;
            display: inline-block;
        }
        

    }
    main{
        width: 27.375rem;
        height: 11.625rem;
        box-sizing: border-box;
        padding-left:2.1875rem ;
        ul{
            li{
                text-align: center;
                font-weight: 400;
                &:nth-child(2){
                    width:4.875rem;
                    height: 4.8125rem;
                    border-radius: 50%;
                    margin: .75rem 0 .75rem 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}
</style>