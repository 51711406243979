<!--  -->
<template>
<div class='Hot'>
    <div class="title">
        <img src="../../assets/img/hot-icon.png"/>
        <h2>花园热门需求词</h2>
    </div>

    <div id="popular" :style="{width:'537px',height:'222px'}"></div>
</div>
</template>


<script>
import * as echarts from "echarts";
export default{
    name:'MyHot',
    data(){
        return{

        }
    },

    mounted(){
        this.hot();
    },

    methods:{
        hot(){
            let myChart=echarts.init(document.getElementById("popular"))
            this.Option={
                
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    top:17,
                    containLabel: true
                },
                xAxis: {
                    type:"value",
                    max:80,
                    splitLine: {show: false},
                    data: ['0','20','40','60','80'],
                    axisLabel:{
                        color:'#656E78',
                        fontSize:12,
                    },
                    axisLine:{
                        show:false
                    },
                    splitNumber:5,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ['#154145 '],
                            width: 2,
                            type: 'solid',
                        },
                    },
                },
                yAxis: {
                    type: 'category',
                    data: ['树木发霉了怎么办', '怎么驱蚊','苔藓处理','水池碧藻过多','植物补种','除杂草' ],
                    axisLabel: {
                      textStyle: {
                          color: '#ffffff', //y轴字体颜色
                          fontSize: '20',
                          fontWeight:400,
                        },
                    },
                    axisLine:{
                        show:false
                    },
                },
                series: [
                    {
                        type: 'bar',
                        stack: 'total',
                        barWidth:8,
                        data: [11,22,25,20,23,19],
                        barCateGoryGap:20,
                        itemStyle:{
                        
                            barBorderRadius: 4,
                            color: new echarts.graphic.LinearGradient(
                                    1,0,0,0,[{
                                        offset:0,
                                        color:'#35FBFF'
                                    },
                                    {
                                        offset:1,
                                        color:'#1C88F2'
                                    }
                                ]
                            )

                            
                        },
                    },
                    {
                        type: 'bar',
                        stack: 'total',
                        name:'hot2',
                        barWidth:8,
                        data: [14,15,19,28,35,45],
                        itemStyle:{
                        barBorderRadius: 4,
                        color:'#31E9FD ',
                            // color: new echarts.graphic.LinearGradient(
                            //         1,0,0,0,[{
                            //             offset:0,
                            //             color:'#1C88F2'
                            //         },
                            //         {
                            //             offset:1,
                            //             color:'#35FBFF'
                            //         }
                            //     ]
                            // )
                        },
                    },
                ]
            },

            myChart.setOption(this.Option,true);

            window.addEventListener('resize',()=>{
                myChart.resize();
            });
        }
       
    }
}
</script>


<style lang='scss' scoped>
.Hot{
    width: 36.375rem;
    height: 19.3125rem;
    background: url(../../assets/img/hot.png) no-repeat;
    box-sizing: border-box;
    padding: 1.25rem 1.4375rem 1.125rem 1.5625rem;
    .title{
        margin-bottom: 1.5rem;
        img{
            height: 1.3125rem;
            width: 1.125rem;
            margin-right:.625rem;
        }
        h2{
            display: inline-block;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
        }
    }
}
</style>