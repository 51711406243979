<!--  -->
<template>
    <div class='app'>
        <!-- 头部导航 -->
        <div class="pc">
            <MyHeader></MyHeader>  
            <div class="banner-pc">
                <el-carousel indicator-position="none">
                    <el-carousel-item v-for="item in bannerList" :key="item.id">
                    <img :src="require(`@/assets/img/${item.src}.jpg`)"/>
                    </el-carousel-item>
                </el-carousel>
            </div>

            <div class="about-pc">
                <div class="banxin height-590">
                    <h2 class="height-49">关于我们</h2>
                    <div class="bottom flex">
                        <div class="b-left">
                            <h3>深圳市两只蜻蜓智能科技有限公司</h3>
                            <h4>SHENZHENBANMUZHINENG LTC.</h4>
                            <p>深圳市两只蜻蜓智能科技有限公司，我们本着让花园消费在数字化的赋能下更透明，更纯粹的使命，为您的花园建立一套智能化管理系统。赋予花园数字生命，简单决策，纯粹生活，成为全国领先的一站式智慧花园数字化建设服务商。</p>
                            <div class="look">查看详情></div>
                        </div>
                        <div class="b-right flex">
                            <img src="../../assets/img/about.png"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="main-pc height-654 bg-f">
                <div class="banxin">
                    <h2>核心价值</h2>
                    <div class="bottom flex">
                        <div 
                        class="son" 
                        v-for="item in valueList"
                        :key="item.id"
                        >
                            <img class="height-273 width-373" :src="require(`@/assets/img/${item.url}`)"/>
                            <div>
                                <p>{{item.num}}</p>
                                <span>{{item.name}}</span>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div class="aside-pc">
                <div class="banxin flex">
                    <ul v-for="item in 4" :key="item">
                        <li>3000+</li>
                        <li>服务用户</li>
                    </ul>
                </div>
            </div>

            <section>
                <div class="banxin">
                    <div class="top flex">
                        <img src="../../assets/img/system1.jpg"/>
                        <div class="digitalization">
                            <h2 class="font-22 color-33 ft-w400 m-b-14">花园通数字化管理云平台</h2>
                            <h3 class="font-18 ft-w400 color-66">深度赋能  降本增效</h3>
                            <div class="icon">
                                <ul 
                                v-for="(item) in iconList1" 
                                :key="item.id"
                                >
                                    <li><img :src="require(`@/assets/img/${item.url}`)"/></li>
                                    <li>{{item.title}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="bottom  flex">
                        <div class="intelligent">
                            <h2 class="font-22 color-33 ft-w400 m-b-14">别墅智能花园管理系统</h2>
                            <h3 class="font-18 ft-w400 color-66">无感监测  有感服务</h3>
                            <div class="icon">
                                <ul 
                                v-for="(item) in iconList2" 
                                :key="item.id"
                                >
                                    <li><img :src="require(`@/assets/img/${item.url}`)"/></li>
                                    <li>{{item.title}}</li>
                                </ul>
                            </div>
                        </div>
                        <img src="../../assets/img/system2.jpg"/>
                    </div>
                </div>
            </section>

            <div class="settle-pc  width-q bg-f">
                <div class="banxin height-1037 width-1200">
                    <h2 class="height-49 width-q">园林庭院智能化解决方案</h2>
                    <img src="../../assets/img/settle.jpg"/>
                </div>
            </div>

            <div class="footer-pc">
                <div class="banxin height-380 width-1200">
                    <div class="top flex">
                        <img src="../../assets/img/footer.png"/>
                        <ul class="nav">
                            <li>网站导航</li>
                            <li>首页</li>
                            <li>系统Demo演示</li>
                            <li>关于我们</li>
                        </ul>
                        <ul class="link">
                            <li>联系我们</li>
                            <li>4000900668</li>
                            <li>深圳市宝安区西乡街道凤凰岗社区水库路113号4层</li>
                        </ul>
                        <div class="concern">
                            <p>关注我们</p>
                            <div class="concern-bottom flex">
                                <ul>
                                    <img src="../../assets/img/wx.jpg"/>
                                    <li>官方微信公众号</li>
                                </ul>
                                <ul>
                                    <img src="../../assets/img/wx.jpg"/>
                                    <li>官方企业微信</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                       © Copyright 2022 深圳市两只蜻蜓智能科技有限公司   <a  style="display:inline-block;color:#ffffff;" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022128793号-1</a>
                    </div>

                </div>
            </div>
        </div>


        <div class="mobile">
            <MyHeaderMb/>
            <div class="banner-mb">
                <el-carousel indicator-position="none">
                    <el-carousel-item v-for="item in bannerList" :key="item.id">
                    <img :src="require(`@/assets/mbimg/${item.src}.jpg`)"/>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="about-mb">
                <div class="banxin">
                    <h2>关于我们</h2>
                    <img src="@/assets/mbimg/about.png" class="aboutlogo"/>
                    <h3>深圳市两只蜻蜓智能科技有限公司</h3>
                    <h4>SHENZHENBANMUZHINENG LTC.</h4>
                    <p>深圳市两只蜻蜓智能科技有限公司，我们本着让花园消费在数字化的赋能下更透明，更纯粹的使命，为您的花园建立一套智能化管理系统。赋予花园数字生命，简单决策，纯粹生活，成为全国领先的一站式智慧花园数字化建设服务商。</p>
                    <div class="look">查看详情 ></div>
                </div>
            </div>
            <div class="main-mb">
                <div class="banxin">
                    <h2>核心价值</h2>
                    <div class="bottom">
                        <div class="value">
                            <img src="@/assets/mbimg/value1.jpg"/>
                            <div class="son">
                                <p>01
                                    <span>算法即服务</span>
                                </p>
                                
                            </div>
                        </div>
                        <div class="value">
                            <div class="son">
                                <p>02
                                    <span>数字化闭环</span>
                                </p>
                               
                            </div>
                            <img src="@/assets/mbimg/value2.jpg"/>
                        </div>
                        <div class="value">
                            <img src="@/assets/mbimg/value3.jpg"/>
                            <div class="son">
                                <p>03
                                    <span>行业标准化</span>
                                </p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div class="banxin">
                    <div class="section">
                        <img src="@/assets/mbimg/section1.jpg"/>
                        <h2>花园通数字化管理云平台</h2>
                        <div class="icon">
                            <ul v-for="item in iconList1" :key="item.id">
                                <img :src="require(`@/assets/mbimg/${item.url}`)"/>
                                <li>{{item.title}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="section">
                        <img src="@/assets/mbimg/section2.jpg"/>
                        <h2>别墅智能花园管理系统</h2>
                        <div class="icon">
                            <ul v-for="item in iconList2" :key="item.id">
                                <img :src="require(`@/assets/mbimg/${item.url}`)"/>
                                <li>{{item.title}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div class="settle-mb">
                <div class="banxin">
                    <h2>园林庭院智能化解决方案</h2>
                    <img src="@/assets/mbimg/settle.jpg"/>
                </div>
            </div>
            <div class="footer">
                <div class="banxin">
                    <div class="left">
                        <p>深圳市两只蜻蜓智能科技有限公司</p>
                        <a  style="color:#ffffff;" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022128793号-1</a>
                    </div>
                    <img src="@/assets/mbimg/btlogo.png"/>
                </div>
            </div>
        </div>
           
    </div>
</template>

<script>
export default {
    data(){
        return{
            bannerList:[
                {id:1,src:"banner1"},
                {id:2,src:"banner2"},

            ],
            valueList:[
                {id:1,num:'01',name:'算法即服务',url:'value1.jpg'},
                {id:2,num:'02',name:'数字化闭环',url:'value2.jpg'},
                {id:3,num:'03',name:'行业标准化',url:'value3.jpg'},
            ],
            iconList1:[
                {id:1,title:'客户管理',url:'client.png'},
                {id:2,title:'精准获客',url:'precise.png'},
                {id:3,title:'服务派单',url:'dispatch.png'},
                {id:4,title:'进度管理',url:'Progress.png'},
                {id:5,title:'工单管理',url:'Workorders.png'},
                {id:6,title:'绩效管理',url:'performance.png'},
                {id:7,title:'财务管理',url:'finance.png'}
            ],
            iconList2:[
                {id:1,title:'专属档案',url:'Exclusive.png'},
                {id:2,title:'结果可视化',url:'outcome.png'},
                {id:3,title:'病虫害预警',url:'Pests.png'},
                {id:4,title:'消杀一体化',url:'sanitize.png'},
                {id:5,title:'水肥浇灌',url:'Water.png'},
                {id:6,title:'护理计划',url:'nursing.png'},
                {id:7,title:'上门服务',url:'Door.png'},
                {id:8,title:'设备租赁',url:'equipment.png'}
            ]

        }
    }
}
</script>

<style lang='scss' scoped>
.pc{
    /deep/ .el-carousel__container{
        height: 40.625rem;
    }
    .el-carousel__item  {
        width: 100%;
    img{
       width: 100%;
       height: 100%;
    }
    }

    .about-pc {
        width: 100%;
        height:590px;
        background: #ffffff;
        .banxin {
            width: 62.5%;
            height: 100%;
            margin: 0 auto;
            box-sizing: border-box;
            padding: 6.25rem 0;

            h2 {
                width: 100%;
                text-align: center;
                font-size: 1.875rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                position: relative;

                &::after {
                    content: '';
                    width: 3.75rem;
                    height: 6px;
                    background: #FFBF59;
                    border-radius: 3px;
                    position: absolute;
                    top:75%;
                    left: 47%;
                    overflow: hidden;
                }
            }

            .bottom {
                width: 100%;
                height: 58.5;
                box-sizing: border-box;
                padding-top: 5.3125rem;

                .b-left {
                        width: 69%;
                        height: 16.375rem;
                    h3 {
                        font-size: 22px;
                        font-weight: 400;
                        color: #333333;
                        margin-bottom: .8125rem;
                    }
                    h4{
                        font-size: 1.0625rem;
                        font-weight: 400;
                        color: #999999;
                        margin-bottom: 2.8125rem;
                    }
                    p{
                        width: 80%;
                        font-size: 16px;
                        font-weight: 400;
                        color: #666666;
                        line-height: 26px;
                        margin-bottom: 2.8125rem;
                    }
                    .look{
                        height: 2.1875rem;
                        width: 6.5625rem;
                        text-align: center;
                        line-height: 2.1875rem;
                        background: #276849;
                        font-size: 14px;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
                .b-right{
                    width:31%;
                    height: 80%;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }

            }
        }
    }
    .main-pc{
        width: 100%;
        .banxin{
            width: 62.5%;
            height: 100%;
            margin: 0 auto;
            h2{
                width: 100%;
                height: 7.5%;
                text-align: center;
                font-size: 1.875rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                position: relative;

                &::after {
                    content: '';
                    width: 3.75rem;
                    height: 6px;
                    background: #FFBF59;
                    border-radius: 3px;
                    position: absolute;
                    top: 75%;
                    left: 47%;
                }
            }
            .bottom{
                width: 100%;
                height: 37.875rem;
                .son{
                    box-shadow: 0px 0px 15px 1px rgba(39,104,73,0.23);
                    width: 31.1%;
                    height: 70.8%;
                    img{
                        width: 100%;
                        height: 63.5%;
                    }
                    div{
                        position: relative;
                        width: 100%;
                        height: 36.5%;
                        p{
                            width: 100%;
                            height: 100%;
                            font-size: 76px;
                            font-weight: bold;
                            color: #F5D49A;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        
                        }
                        span{
                                width: 6.9375rem;
                                height: 1.375rem;
                                font-size: 22px;
                                font-weight: 400;
                                color: #333333;
                                position: absolute;
                                top:0;
                                left:0;
                                bottom: 0;
                                right: 0;
                                margin: auto;
                        }
                    }
                }
            }
        }
    }

    .aside-pc{
        width: 100%;
        height:18.75rem;
        background: url(../../assets/img/aside.jpg);
        .banxin{
            width: 62.5%;
            height: 100%;
            margin: 0 auto;
            ul{
                height: 31.7%;
                width: 14%;
                li{
                    width: 100%;
                    &:nth-child(1){
                        font-size: 3.375rem;
                        font-weight: bold;
                        color: #FFFFFF;
                        margin-bottom: 1.75rem;
                    }
                    &:nth-child(2){
                        font-size: 1.5rem;
                        font-weight: bold;
                        color: #FFFFFF;
                        text-align: center;
                    }

                }
            }
        }
    }

    section{
        margin: 6.3125rem 0;
        height:55.875rem;
        width: 100%;
        .banxin{
            width: 62.5%;
            height: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .top{
                height:46.7%;
                width: 100%;
                background: #FFFFFF;
                box-shadow: 0px 0px 15px 1px rgba(39,104,73,0.23);
                img{
                    width: 34.75%;
                    height: 100%;
                }
                .digitalization{
                    width: 65.25%;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 3.5rem 4.625rem 3.6875rem 3.125rem;
                    h3{
                        width: 10.6875rem;
                        height: 1.125rem;
                        box-sizing: border-box;
                        padding-left: 1.25rem;
                        position: relative;
                        margin-bottom: 3rem;
                        &::after {
                            content: '';
                            width: 6px;
                            height: 16px;
                            background:  #FFBF59;
                            position: absolute;
                            top: 20%;
                            left:0;
                        }
                    }
                    .icon{
                        width:100%;
                        max-height: 12.25rem;
                        
                        ul{
                            display: inline-block;
                            width: 25%;
                            height: 40.5%;
                            box-sizing: border-box;
                            padding: .625rem 0;
                            li{
                                font-size: 16px;
                                font-weight: 400;
                                color: #999999;
                                text-align: center;
                                &:nth-child(1){
                                    width: 100%;
                                    height: 60%;
                                }
                            }
                        }
                    }
                        
                }
            }
            .bottom{
                height:46.7%;
                width: 100%;
                background: #FFFFFF;
                box-shadow: 0px 0px 15px 1px rgba(39,104,73,0.23);
                img{
                    width: 34.75%;
                    height: 100%;
                }
                .intelligent{
                    width: 65.25%;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 3.5rem 4.625rem 3.6875rem 3.125rem;
                    h3{
                        width: 10.75rem;
                        height: 1.125rem;
                        box-sizing: border-box;
                        padding-left: 1.25rem;
                        position: relative;
                        margin-bottom:2.9375rem;
                        &::after {
                            content: '';
                            width: 6px;
                            height: 16px;
                            background:  #FFBF59;
                            position: absolute;
                            top: 20%;
                            left:0;
                        }
                    }
                    .icon{
                        width:100%;
                        max-height: 12.25rem;
                        
                        ul{
                            display: inline-block;
                            width: 25%;
                            height: 40.5%;
                            box-sizing: border-box;
                            padding: .625rem 0;
                            li{
                                font-size: 16px;
                                font-weight: 400;
                                color: #999999;
                                text-align: center;
                                &:nth-child(1){
                                    width: 100%;
                                    height: 60%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .settle-pc{
        width: 100%;
        height: 64.8125rem;
        .banxin{
            width: 62.5%;
            height: 100%;
            margin: 0 auto;
            box-sizing: border-box;
            padding-bottom:6.25rem ;
            h2{
                font-size: 30px;
                font-weight: bold;
                color: #333333;
                text-align: center;
                position:relative;
                margin-bottom: 5.5625rem;
                &::after {
                            content: '';
                            width: 60px;
                            height: 6px;
                            border-radius: 3px;
                            background:  #FFBF59;
                            position: absolute;
                            top: 75%;
                            left: 47%;
                }
            }
            img{
                width: 100%;
                height: 76.85%;
            }
        }
    }

    .footer-pc{
        width: 100%;
        height:23.75rem;
        background:#276849;;
        .banxin{
            width: 62.5%;
            height: 100%;
            margin:0 auto;
            box-sizing: border-box;
            padding: 5.3125rem 0 2.4375rem 0;
            .top{
                height: 10.3125rem;
                margin-bottom: 4.6875rem;
                img{

                }
                .nav{
                    height: 10.3125rem;
                    width: 6.1875rem;
                    li{
                        margin-bottom: 1.875rem;
                        font-size: 14px;
                        font-weight: 400;
                        color: #FFFFFF;
                        &:nth-child(1){
                            font-size: 18px;
                            font-weight: bold;
                            color: #FFFFFF;
                            position: relative;
                            &::after{
                                content: '';
                                width: 46px;
                                height: 2px;
                                background:#FFBF59;
                                position: absolute;
                                top: 1.875rem;
                                left: 0;
                            }
                        }
                    }
                }
                .link{
                    height: 10.3125rem;
                    width: 9.375rem;
                    li{
                        margin-bottom: 1.875rem;
                        font-size: 14px;
                        font-weight: 400;
                        color: #FFFFFF;
                        &:nth-child(1){
                            font-size: 18px;
                            font-weight: bold;
                            color: #FFFFFF;
                            position: relative;
                            &::after{
                                content: '';
                                width: 46px;
                                height: 2px;
                                background:#FFBF59;
                                position: absolute;
                                top: 1.875rem;
                                left: 0;
                            }
                        }
                    }
                }
                .concern{
                    height: 10.3125rem;
                    width: 14.25rem;
                    p{
                        font-size: 18px;
                        font-weight: bold;
                        color: #FFFFFF;
                        position: relative;
                        margin-bottom:1.875rem;
                            &::after{
                                content: '';
                                width: 46px;
                                height: 2px;
                                background:#FFBF59;
                                position: absolute;
                                top: 1.875rem;
                                left: 0;
                            }
                    }
                    .concern-bottom{
                        ul{
                            li{
                                font-size: 12px;
                                font-weight: 300;
                                color: #FFFFFF;
                                margin-top: .75rem;
                            }
                        }
                    }
                }
            }

            .bottom{
                width: 100%;
                font-size: 14px;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
            }
        }
    }
}

.mobile{
        .banner-mb{
            /deep/ .el-carousel__container{
            width: 100%;
            overflow: hidden;
            margin: 0;
            padding-bottom: 100%;
            }
        .el-carousel__item  {
        img{
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
        }
        }
        }

        .about-mb{
            width: 100%;
            max-height : 51.5rem;
            background: #ffffff;
            .banxin{
                width:91.33%;
                height: 100%;
                max-height: 100%;
                margin:0 auto;
                padding:7.4% 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                h2{
                    height: 7.28%;
                    width: 100%;
                    font-size:calc(100vw * 36/ 750); 
                    font-weight: bold;
                    color: #333333;
                    position: relative;
                    box-sizing: border-box;
                    padding-left: 1.5rem;
                    display: flex;
                    align-items:center ;
                    &::after{
                        content: '';
                        width: 1.02%;
                        height: 70%;
                        background: #F5C16A;
                        position: absolute;
                        left: 0;
                        top: 25%;
                    }
                }
                .aboutlogo{
                    width: 49.7%;
                    height: 26.04%;
                    margin-top: 7.76%;
                    margin-bottom: 5.7%;
                }
                h3{
                    width: 53%;
                    height: 3.5%;
                    font-size: calc(100vw * 30/ 750);
                    font-weight: 400;
                    color: #333333;
                }
                h4{
                    width: 100%;
                    height: 1.82%;
                    font-size: calc(100vw * 20/ 750);
                    font-weight: 400;
                    color: #999999;
                    text-align: center;
                    margin-top: 1.46%;
                    margin-bottom: 5.83%;
                }
                p{
                    width: 100%;
                    height: 15.5%;
                    font-size: calc(100vw * 24/750);
                    font-weight: 400;
                    color: #666666;
                    line-height: 2.125rem;
                    margin-bottom:6.06% ;
                }
                .look {
                    width: 24.8%;
                    height: 8.2%;
                    background: #276849;
                    display:flex ;
                    justify-content: center;
                    align-items: center;
                    font-size: calc(100vw * 24/750);
                    font-weight: 400;
                    color: #FFFFFF;
                    padding-top:2.3% ;
                    padding-bottom:  2.3%;
                    float: right;
                }


                
            }
        }

        .main-mb{
            width: 100%;
            max-height:53rem;
            background: #FFFFFF;
            .banxin{
                width: 91.06%;
                height: 100%;
                margin: 0 auto;
                h2{
                    height: 4.24%;
                    width: 100%;
                    font-size:calc(100vw * 36/ 750); 
                    font-weight: bold;
                    color: #333333;
                    position: relative;
                    box-sizing: border-box;
                    padding-left: 1.5rem;
                    display: flex;
                    align-items:center ;
                    margin-bottom:4.71%;
                    &::after{
                        content: '';
                        width: 1.02%;
                        height: 70%;
                        background: #F5C16A;
                        position: absolute;
                        left: 0;
                        top: 25%;
                    }
                }
                .bottom{
                    width: 100%;
                    height: 91.16%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    .value{
                        width: 100%;
                        height: 31%;
                        box-shadow: 0px 0px .5rem 0px rgba(39,104,73,0.23);
                        box-sizing: border-box;
                        margin-bottom:3.5%;
                        display: flex;
                        img{
                            height: 100%;
                            width: 61.5%;
                        }
                        .son{
                            width: 38.5%;
                            height: 100%;
                            box-sizing: border-box;
                            padding-top: 17%;
                            padding-left: 12%;
                            p{
                                width: 100%;
                                height: 28.93%;
                                font-size: calc(100vw * 88/750);
                                font-weight: bold;
                                color: #FBD292;
                                position:relative ;
                                span{
                                font-size:calc(100vw * 30/750);
                                font-weight: 400;
                                color: #333333;
                                position: absolute;
                                top: 15%;
                                left:-13%;
                                 }
                            }
                           
                        }
                    }
                }
            }
        }
    
        section{
            width: 100%;
            max-height:88.6875rem;
            background: #FFFFFF;
            .banxin{
                width: 91.5%;
                height: 100%;
                margin: 0 auto;
                box-sizing: border-box;
                padding-top:4.16% ;
                padding-bottom:3.95%;
                .section{
                    width: 100%;
                    height: 45.1%;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 12px 0px rgba(39,104,73,0.23);
                    &:nth-child(1){
                        margin-bottom:2.1%;
                    }
                    img{
                        width: 100%;
                        height: 44.1%;
                        margin-bottom: 3.46%;
                    }
                    h2{
                        width: 100%;
                        height: 8.66%;
                        display: inline-block;
                        text-align: center;
                        font-size: calc(100vw * 30/750);
                        font-weight: 400;
                        color: #333333;
                        position: relative;
                        &::after{
                            content: '';
                            width: 10.5%;
                            height: 12.96%;
                            background: #F5C16A;
                            position: absolute;
                            bottom: -80%;
                            left: 45%;
                        }
                    }
                    .icon{
                        width: 100%;
                        height: 44.73%;
                        box-sizing: border-box;
                        padding-top:3.46%;
                        padding-bottom:4.68%;
                        ul{
                            display: inline-block;
                            width: 25%;
                            height: 33.5%;
                            margin-top:5%;
                            text-align: center;
                            img{
                                height: 40%;
                                width: 30%;
                            }
                            li{
                                font-size: calc(100vw * 22/750);
                                font-weight: 400;
                                color: #999999;
                            }
                        }
                    }
                }
                
            }
        }

        .settle-mb{
            width: 100%;
            max-height: 38.25rem;
            background: #FFFFFF;
            .banxin{
                width:91.33%;
                height: 100%;
                margin: 0 auto;
                box-sizing: border-box;
                padding-top: 4.08%;
                padding-bottom:9.97%;
                h2{
                    height: 5.72%;
                    width: 100%;
                    font-size:calc(100vw * 36/ 750); 
                    font-weight: bold;
                    color: #333333;
                    position: relative;
                    box-sizing: border-box;
                    padding-left: 1.5rem;
                    display: flex;
                    align-items:center ;
                    margin-bottom:6.69%;
                    &::after{
                        content: '';
                        width: 1.02%;
                        height: 70%;
                        background: #F5C16A;
                        position: absolute;
                        left: 0;
                        top: 25%;
                    }
                }
                img{
                    width: 100%;
                    height: 74.02%;
                }
            }
        }

        .footer{
            width: 100%;
            max-height: 6.25rem;
            background: #276849;
            .banxin{
                width: 91.33%;
                height: 100%;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding-top: 2.8%;
                padding-bottom:2.8%;
                .left{
                    width: 69.4%;
                    height: 44%;
                    font-size: calc(100vw * 16/750);
                    font-weight: 400;
                    color: #FFFFFF;
                }
                img{
                    width: 40.6%;
                    height: 44%;
                }
            }
        }
}
</style>