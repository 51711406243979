import Vue from "vue";
import VueRouter from 'vue-router'
import index from '@/views/pc/index.vue'
import LargeScreen from '@/views/pc/LargeScreen.vue'
import introduce from '@/views/pc/introduce.vue'
import root from '@/views/pc/root.vue'
import equipment from '@/views/pc/equipment'
Vue.use(VueRouter)

var routes = [
    {
        path:'/',
        redirect:'/index'
    },
    {
        path:'/index',
        component:index
    },
    {
        path:'/LargeScreen',
        component:LargeScreen
    },
    {
        path:'/introduce',
        component:introduce
    },
    {
        path:'/root',
        name:'root',
        component:root
    },
    {
        path:'/equipment',
        component:equipment
    }
]


    





const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
  })


export default router