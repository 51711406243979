<!-- 我的经营组件 -->
<template>
<div class='Business'>
    <div class="top">
        <img src="../../assets/img/message.png"/>
        <h2>我的经营</h2>
    </div>
    <div class="bottom flex">
        <div class="title">
            <ul class="flex" v-for="(item) in titleList" :key="item.id">
                <li class="color-f">{{item.title}}</li>
                <li class="color-35FBFF">{{item.num}}</li>
            </ul>
        </div>
        <div class="curcal ">
            <div id="main" :style="{width:'350px',height:'374px'}"></div>
        </div>
        
    </div>
</div>
</template>


<script>
import * as echarts from "echarts";
export default{
    name:'MyBusiness',
    data(){
        return{
            titleList:[
                {id:1,title:'我的半亩花园(户)',num:320},
                {id:2,title:'总成交额(元)',num:165500},
                {id:3,title:'新签单用户数',num:25},
                {id:4,title:'新增订单数',num:44},
                {id:5,title:'售后订单数',num:9},
                {id:6,title:'交付评分',num:99}
            ]
        }
    },

    mounted(){
       this.drawLine();
    },

    methods:{
        drawLine(){
            let myChart=echarts.init(document.getElementById("main"))
            this.Option={
                series: [
                    {
                    name: 'Radius Mode',
                    type: 'pie',
                    radius: [30, 130],
                    width:350,
                    height:374,
                    // left: "50%",
                    // top: "50%",
                    center: ['48%', '50%'],
                    roseType: 'radius',
                    itemStyle: {
                        borderRadius: 8
                    },
                    label: {
                        show: false
                    },
                    emphasis: {
                        label: {
                        show: true
                        }
                    },
                    data: [
                        { value: 38, name: '江北区17.5%',
                          itemStyle:{
                            color:'rgba(0, 202, 206, 0.4)',
                          }
                        },
                        { value: 33, name: '渝中区15.2%',
                            itemStyle:{
                                color:'rgba(255, 248, 53, 0.4)',
                            }
                         },
                        { value: 26, name: '渝北区11.9%',
                            itemStyle:{
                                    color:'rgba(255, 149, 47, 0.4)',
                                }
                         },
                        { value: 28, name: '沙坪坝区12.9%',
                            itemStyle:{
                                    color:'rgba(255, 110, 110, 0.4)',
                                }
                         },
                        { value: 24, name: '九龙波区11%',
                            itemStyle:{
                                     color:'rgba(120, 255, 53, 0.4)',
                                }
                         },
                        { value: 27, name: '南岸区12.4%',
                            itemStyle:{
                                    color:'rgba(255, 248, 53, 0.4)',
                                }
                         },
                        { value: 24, name: '北碚区11%',
                            itemStyle:{
                                    color:'rgba(0, 202, 206, 0.4)',
                                }
                         },
                        { value: 17, name: '大渡口区7.8%',
                            itemStyle:{
                                    color:'rgba(255, 110, 110, 0.4)',
                                }
                        },
                        { value: 12, name: '巴南区5.8%',
                            itemStyle:{
                                    color:'rgba(120, 255, 53, 0.4)',
                                }
                        }
                    ]
                    }
                ]
               
            }




            myChart.setOption(this.Option,true);

            window.addEventListener('resize',()=>{
                myChart.resize();
            });
        }
    }
}
</script>



<style lang='scss' scoped>
    .Business{
        width: 42.5625rem;
        height: 27.875rem;
        box-sizing: border-box;
        padding:1.4375rem 0 0 1.75rem;
        .top{
            margin-bottom: 2.9375rem;
            img{
            width:1.125rem;
            height: 1.0625rem;
                margin-right: .625rem;
            }
            h2{
                display: inline-block;
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
            }
        }
        
        .bottom{
            width: 40.875rem;
            height: 21.875rem;
            .title{
                width: 18.6875rem;
                height: 21.875rem;
                box-sizing: border-box;
                padding: 1.6875rem 0 2.5rem 0;
                ul{
                    li{
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        margin-bottom: 1.8125rem;
                        &:nth-child(2){
                            width: 6.125rem;
                        }
                    }
                }
            }
            .curcal{
                width: 22.25rem;
                height: 21.875rem;
                background: url(../../assets/img/center-circle.png) no-repeat;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
</style>