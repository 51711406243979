<!--  -->
<template>

    <!-- 大盘数据组件 -->
<div class='BigData '>  
      <div class="title">
        <img src="../../assets/img/count.png"/>
        <h2>重庆市大盘数据</h2>
      </div> 
      <div class="data flex width-q font-20">
        <ul v-for="(item) in DataList" :key="item.id">
            <li class="ft-w400 center color-f m-b-14">{{item.title}}</li>
            <li class="color-35FBFF ft-w400 center m-b-25">{{item.count}}</li>
            <li class="ft-w400 color-f m-b-14 center">{{item.title1}}</li>
            <li class="color-35FBFF ft-w400 center">{{item.count1}}</li>
        </ul>
      </div>
</div> 

</template>

<script>
    
    export default {
        name:"MyBigData",
        data() {
            return{
                DataList:[
                    {id:1,title:'花园数量(户)',count:18860,title1:'总工单(笔)',count1:2361},
                    {id:2,title:'订购服务比例',count:'86%',title1:'服务销售额(元)',count1:10015482},
                ]
            }
        },
        methods: {
           
        }
  }
</script>

<style lang='scss' scoped>
//@import url(); 引入公共css类
.BigData{
    width: 32.625rem;
    height: 15.3125rem;
    background: url(../../assets/img/border.png) no-repeat;
    box-sizing: border-box;
    padding:1.6875rem 5.125rem 2.9375rem 1.875rem ;
    .title{
        margin-bottom: 1.5625rem;
        img{
            width: 1.125rem;
            height: 1.0625rem;
        }
        h2{
            width: 10.5625rem;
            height: 1.5rem;
            font-size: 1.5rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            display: inline-block;
            margin-left:.4375rem;
        }
    }
    .data{
        box-sizing: border-box;
        padding-left:3.0625rem;
    }
}
</style>