<!--  -->
<template>
<div class='introduce'>
    <div class="introduce-pc">
        <MyHeader></MyHeader>
        <div class="introduce-kong-pc">
            <el-empty :image-size="200" description="暂无内容"></el-empty>
        </div>
    </div>

    <div class="introduce-mb">
        <MyHeaderMb></MyHeaderMb>
        <div class="introduce-kong-pc">
            <el-empty :image-size="200" description="暂无内容"></el-empty>
        </div>
    </div>
</div>
</template>
<script>
export default{
    data(){
        return{

        }
    }
}
</script>
<style lang='scss' scoped>

.introduce-kong-pc{
        display: block;
        width: 100%;
        height: 43.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
}


  
</style>