<!--  -->
<template>

  <!-- 头部导航栏组件 --> 
  <div class="header-pc">
    <div class="header flex">
        <div class="banxin flex">
            <img src="../../assets/img/logo.png"/>
            <ul class="height-q font-14 color-33 ft-w400">
                <li  v-for="(item, index) in MenuList" 
                     :key="index"
                     :class="index== active?'active':''"
                     @mousemove="active = index"
                     @mouseout="active = null"
                     @click="toPage(index)"
                     >
                    {{ item.titleName }}
                </li>
            </ul>
        </div>
    </div> 
  </div>
</template>


<script>
    export default{
        name:'MyHeader',
        data(){
            return{
                MenuList:[
                {activeName: 'Index', titleName: '首页', activeUrl: '/index'},
                {activeName: 'Introduce', titleName: '关于我们', activeUrl: '/introduce'},
                {activeName: 'LargeScreen', titleName: '系统演示', activeUrl: '/LargeScreen'},
                {activeName:'equipment',titleName:'驱蚊设备',activeUrl:'/equipment'}
                ],
                active:0,
            }
        },
        created(){
            this.active = this.$route.query.active
        },
        methods:{
            toPage (e) {
                switch (e) {
                    case 0:
                    this.$router.push({ path: '/index', query: { active: this.active } })
                    break
                    case 1:
                    this.$router.push({ path: '/introduce', query: { active: this.active } })
                    break
                    case 2:
                    this.$router.push({ path: '/LargeScreen', query: { active: this.active } })
                    break
                    case 3:
                    this.$router.push({ path: '/equipment', query: { active: this.active } })   
                    }
            }


        }
            
    }
</script>


<style lang='scss' scoped>
//@import url(); 引入公共css类
    .header-pc{
        width: 100%;
        height: 5rem;
        .header{
        width: 100%;
        height: 5rem;
        background: #ffffff;
        position: fixed;
        top: 0;
        z-index: 999;
        overflow: hidden;
        .banxin{
            width: 62.5%;
            height: 100%;
            margin: 0 auto;
            ul{
                width:41.6%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                li{
                height: 100%;
                width: 33.33%;
                font-size: 1.125rem;
                font-weight: 400;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            }
            
        }
    }
            .active{
                cursor: pointer;
                background: #276849;
                color: #ffffff;
            }

        }
</style>






 