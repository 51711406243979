<!--  -->
<template>
<div class='header-mb'>
    <div class="banxin">
        <div class="left">
            <img src="@/assets/mbimg/toplogo.png" class="toplogo">
        </div>
        <div class="right">
            <img src="@/assets/mbimg/top.jpg" @click="handleClick()"/>
            <div class="dropDow" v-show="isShow">
                    <p 
                    v-for="(item,index) in Menulist"
                    :key="item.id"
                    @click="toPage(index)"
                    >{{item.name}}</p>
            </div>
        </div>

    </div>
</div>
</template>
<script>
export default{
    name:'MyHeaderMb',
    data(){
        return{
            isShow:false,
            Menulist:[
                {id:1,name:'首页'},
                {id:2,name:'关于我们'},
                {id:3,name:'系统演示'},
                {id:4,name:'驱蚊设备'}
            ]
        }
    },
    methods:{
        handleClick(){
            this.isShow=!this.isShow
        },
        toPage(index){
            console.log(index);
            if(index==0){
                this.$router.push('/index');
            }
            else if(index==1){
                this.$router.push('/introduce');
            }
            else if(index==2){
                this.$router.push('/LargeScreen');
            }else if(index==3){
                this.$router.push('/equipment');
            }
        }
    }

}
</script>
<style lang='scss' scoped>
    .header-mb{
        width: 100%;
        max-height: 5rem;
        background: #ffffff;
        position: relative;
        .banxin{
            width: 91.33%;
            height: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            .left{
                width: 94.3%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                padding: 1.125rem 0 1rem 0;
                img{
                    width:35.9%;
                    height:61.25%;
                }
            }
            .right{
                width: 5.7%;
                height:100%;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                padding: 1.125rem 0 1rem 0;
                img{
                    width: 100%;
                    height: 50%;
                }

                .dropDow{
                    position: absolute;
                    top: 100%;
                    right: 0;
                    width: 20.43%;
                    background: #276849;
                    z-index: 9999;
                    p{
                        width: 100%;
                        height: 33.3%;
                        font-size:calc(100vw * 24/ 750); 
                        font-weight: 400;
                        color: #FFFFFF;
                        padding: .75rem 0;
                        text-align: center;
                    }
                }
            }
        }
    }

</style>