<!--  -->
<template>
<div class='NowDate'>
    <div>{{nowHour}}</div>  :
    <div>{{nowMiunte}}</div>  :
    <div>{{nowSecond}}</div>
    <p>数据每间隔十分钟刷新一次</p>
</div>
</template>
<script>
export default{
    name:'MyNowDate',
    data(){
        return{
            nowHour:'',
            nowMiunte:'',
            nowSecond:'',
        }
    },
    methods:{
        currentTime(){
            setInterval(this.formatDate,0);
        },
        formatDate(){
            let date=new Date();
            let hour = date.getHours();//时
            hour=hour<10?"0"+hour:hour;
            let minute=date.getMinutes();//分
            minute=minute<10?"0"+minute:minute;
            let second=date.getSeconds();//秒
            second=second<10?"0"+second:second;
            this.nowHour=`${hour}`
            this.nowMiunte=`${minute}`
            this.nowSecond=`${second}`
        }
    },

    mounted(){
        this.currentTime();
    },

    beforeDestroy(){
        if(this.formatDate){
            clearInterval(this.formatDate);
        }
    }

}
</script>
<style lang='scss' scoped>
    .NowDate{
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding-top: .9375rem;
        font-size: 1rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        div{
            width: 2.4375rem;
            height: 2rem;
            background: #0E1F31;
            border: 1px solid #6DA0D4;
            border-radius: .375rem;
            margin-right: .4375rem;
            margin-left: .4375rem;
            text-align: center;
            line-height: 2rem;
            font-size: 1rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
        }
        p{
            text-align: center;
            line-height: 2rem;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
</style>