// f封装组件

import ScaleBox from './ScaleBox';  //大屏适配
import BigData from './BigData' //大盘数据
import  Header  from './Header'; //首页头部组件
import NowDate from './NowDate'; //当前时间显示组件
import Server from './Server';//服务列表组件
import Good from './Good';//优秀服务商组件
import Hot from './hot';//热门词展示组件
import Shop from './Shop';//订购服务前三组件
import Business from './Business';//我的经营组件
import Warning from './warning';//服务预警组件
import Screen from './Screen';
import HeaderMB from './HeaderMB'; //移动端头部组件
import rtmessage from './rtmessage';
import rtdata from './rtdata'; //地图管理员大盘数据
const components={
    ScaleBox,
    BigData,
    Header,
    NowDate,
    Server,
    Good,
    Hot,
    Shop,
    Business,
    Warning,
    Screen,
    HeaderMB,
    rtmessage,
    rtdata
}


const install = (Vue = {}) => {  //install全局注册
    if (install.installed) return;
    Object.keys(components).forEach(component => {
      Vue.component(components[component].name, components[component]);
    });
  
    install.installed = true;
  };
  
  install.installed = false;
  
  if (typeof window !== "undefined" && window.Vue) {
    install(window.Vue);
    install.installed = true;
  }
  
  const Vcomp = {
    ...components,
    install
  };


export default Vcomp