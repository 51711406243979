<template>
  <div class='app'>
    <div class="pc">
        <MyHeader></MyHeader>
        <div class="banner-pc">
            <img src="../../assets/img/eq-banner.png"/>
        </div>
        <div class="brand-pc">
            <div class="banxin">
                <div class="title-box">
                    <div class="chinese-title">品牌简介</div>
                    <div class="english-title">BRAND PROFILE</div>
                </div>
                <div class="content">
                    <img src="../../assets/img/eq-brand.png"/>
                    <div class="arctic">
                        <div style="margin-bottom:1.25rem">Double Dragonflies(两只蜻蜓)是半亩智能旗下核心品牌，专注花园驱蚊领域。拥有国内一流的农业、园林及生态养护技术科研团队，专攻目前市场驱蚊剂型落后带来的环保和安全问题，研发真正意义上的绿色安全驱蚊药剂，达到国际一流水平。业内率先推出新一代庭院智能驱蚊系统，搭载纯天然植物精油，无味无刺激，我们以关爱为基点，创新场景化驱蚊理念有效解决人们在居家、办公和户外等不同场景下的驱蚊需求。</div>
                        <div style="margin-bottom:1.25rem">两只蜻蜓秉承“以智能科技引领未来无蚊生活”的品牌主张，不断探索行业新模式，洞察人们日益多样化的驱蚊诉求和消费习惯。我们的产品以智能便捷的使用体验、安全健康的全场景高效驱蚊及领先的技术优势，深受广大消费者喜爱。</div>
                        <div>两只蜻蜓的品牌理念：源于“安全、方便、有效”的客户诉求。蜻蜓作为大自然中蚊子的天敌，环境的检测专家 ，采用“Double Dragonflies(两只蜻蜓)”作为品牌形象，深刻地诠释着企业用心——坚持行业、 消费者、大自然共生共赢。科技赋能，引领业态绿色升级，同时迭代生活理念，成为消费者心目中理想的健康驱蚊品牌。</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="lovebox-pc">
            <div class="banxin">
                <div class="title-box">
                    <div class="chinese-title">源自关爱</div>
                    <div class="english-title">ORIGINATED WITH CARE</div>
                </div>
                <div class="content-box">
                    <div class="item-box" v-for="item in love" :key="item.id">
                        <img :src="require(`@/assets/img/${item.url}`)"/>
                        <div class="item-bottom">
                            <div class="item-title">{{ item.title }}</div>
                            <div class="item-arcitle">{{ item.arcitle}}</div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="lanyao-pc">
           <div class="banxin flex">
                <ul v-for="item in lanyaoList" :key="item.id">
                        <li>{{ item.name }}</li>
                        <li>{{ item.count }}</li>
                </ul>
           </div>
        </div>
        <div class="productbox-pc">
            <div class="banxin">
                <div class="box-top">
                    <div class="title-box">
                        <div class="chinese-title">产品介绍 —— 驱蚊剂</div>
                        <div class="english-title">PRODUCT INTRODUCTION——MOSQUITO REPELLENT</div>
                    </div>
                    <div class="content">
                        <img src="../../assets/img/eq-product1.png"/>
                        <div class="right">
                            <div class="right-box" style="margin-bottom:2rem">
                                <div class="title">驱蚊精油</div>
                                <div class="articl">严选世界各地十余种优质植物经天然基取，通过先进可靠的配方和工艺有机融合在一起，实现了技术性突破成将母婴级安全品质，不仅能像常规驱蚊精油喷涂于皮肤表面，保护皮肤避免蚊虫叮咬，用于室外环境下喷雾驱蚊效果亦十分理想。</div>
                            </div>
                            <div class="right-box">
                                <div class="title">除虫菊酯</div>
                                <div class="articl">世界卫生组织推荐用药，广泛应用于卫生害虫防治，是干燥菊属植物中的除虫菊花的提取物，也是一类常见的广谱杀虫剂，如生活中使用的蚊香、品牌杀虫剂、部分治疗猫醉及消除跳蚤的外用药等，都含有除虫菊脂的成分，其具有高效、安全、用药量小、易降解、代谢快等优势。</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-bottom">
                    <div class="title-box">
                        <div class="chinese-title">产品介绍 —— 设备</div>
                        <div class="english-title">TECHNOLOGY INTEGRATION × NEW POWER TO REPEL MOSQUITOES</div>
                    </div>
                    <div class="content">
                        <div class="content-left">
                            <div class="left-box" v-for="item in product" :key="item.id">
                                <img :src="require(`@/assets/img/${item.url}`)"/>
                                <div class="name">{{ item.name }}</div>
                                <div class="pro-intro">
                                    <div class="intro-item">最大喷头数：{{ item.Nozzle }}</div>
                                    <div class="intro-item">面积：{{ item.area }}</div>
                                    <div class="intro-item">功能：{{ item. function}}</div>
                                    <div class="intro-item" v-if="item.size">外箱尺寸：{{ item.size }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="content-right">
                            <div class="right-title">更多可能</div>
                            <div class="right-other">敬请期待>></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="aside-pc">
            <div class="banxin">
                <div class="aside-item" v-for="item in asideList" :key="item.id">
                    <div class="top">
                        <img :src="require(`@/assets/img/${item.url}`)"/>
                    </div>
                    <div class="tag">{{ item.tag }}</div>
                    <div class="tag1">{{ item.tag1 }}</div>
                </div>
            </div>
        </div>
        <div class="serive-pc">
            <div class="banxin">
                <div class="title-box">
                    <div class="chinese-title">打造一站式服务体系</div>
                    <div class="english-title">CREATING A ONE-STOP SERVICE SYSTEM</div>
                </div>
                <div class="content">
                    <div class="serive-item" v-for="item in seriveList" :key="item.id">
                        <img :src="require(`@/assets/img/${item.url}`)"/>
                        <div class="tag">{{ item.tag }}</div>
                        <div class="text">{{ item.text }}</div>
                        <div class="text1">{{ item.text1 }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="classic-pc">
            <div class="banxin">
                <div class="title-box">
                    <div class="chinese-title">经典案例</div>
                    <div class="english-title">CLASSIC CASES</div>
                </div>
                <div class="classic-top">
                    <div class="top-left">
                       <div class=" littleTitle-box">
                            <div class="chinese-title">别墅应用案例</div>
                            <div class="fu-title">助力全家人的闲适时光</div>
                       </div>
                       <div class="content">
                            <img v-for="item in villa" :key="item.id" :src="require(`@/assets/img/${item.url}`)"/>
                       </div>
                    </div>
                    <div class="top-right">
                        <div class="school-box">
                            <div class=" littleTitle-box">
                                <div class="chinese-title">校园应用案例</div>
                                <div class="fu-title">从容享受每一场户外体验</div>
                            </div>
                            <div class="content">
                                <div class="content-box">
                                    <img src="../../assets/img/eq-school1.png"/>
                                    <div class="img-text">操场</div>
                                </div>
                                <div class="content-box">
                                    <img src="../../assets/img/eq-school2.png"/>
                                    <div class="img-text">幼儿园</div>
                                </div>
                            </div>
                        </div>
                        <div class="school-box" style="margin-bottom:0rem">
                            <div class=" littleTitle-box">
                                <div class="chinese-title">商家应用案例</div>
                                <div class="fu-title">助力经营者提升服务品质</div>
                            </div>
                            <div class="content">
                                <div class="content-box">
                                    <img src="../../assets/img/eq-sale1.png"/>
                                    <div class="img-text">高尔球场</div>
                                </div>
                                <div class="content-box">
                                    <img src="../../assets/img/eq-sale2.png"/>
                                    <div class="img-text">农庄</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="classic-middle">
                    <div class="middle-left">
                        <div class=" littleTitle-box">
                            <div class="chinese-title">公园应用案例</div>
                            <div class="fu-title">打造更闲适的户外时光</div>
                       </div>
                       <div class="content">
                            <img v-for="item in parkList" :key="item.id" :src="require(`@/assets/img/${item.url}`)"/>
                       </div>
                    </div>
                    <div class="middle-left">
                        <div class=" littleTitle-box">
                            <div class="chinese-title">餐厅应用案例</div>
                            <div class="fu-title">打造更舒适的用餐环境</div>
                       </div>
                       <div class="content">
                            <img v-for="item in restaurant" :key="item.id" :src="require(`@/assets/img/${item.url}`)"/>
                       </div>
                    </div>
                </div>
                <div class="classic-bottom">
                    <div class=" littleTitle-box">
                        <div class="chinese-title">企业应用案例</div>
                        <div class="fu-title">打造更专业的企业形象</div>
                    </div>
                    <div class="content">
                        <img v-for="item in business" :key="item.id" :src="require(`@/assets/img/${item.url}`)"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-pc">
                <div class="banxin height-380 width-1200">
                    <div class="top flex">
                        <img src="../../assets/img/footer.png"/>
                        <ul class="nav">
                            <li>网站导航</li>
                            <li>首页</li>
                            <li>系统Demo演示</li>
                            <li>关于我们</li>
                        </ul>
                        <ul class="link">
                            <li>联系我们</li>
                            <li>4000900668</li>
                            <li>深圳市宝安区西乡街道凤凰岗社区水库路113号4层</li>
                        </ul>
                        <div class="concern">
                            <p>关注我们</p>
                            <div class="concern-bottom flex">
                                <ul>
                                    <img src="../../assets/img/wx.jpg"/>
                                    <li>官方微信公众号</li>
                                </ul>
                                <ul>
                                    <img src="../../assets/img/wx.jpg"/>
                                    <li>官方企业微信</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                       © Copyright 2022 深圳市两只蜻蜓智能科技有限公司     <a  style="display:inline-block;color:#ffffff;" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022128793号-1</a>
                    </div>

                </div>
        </div>
    </div>
    <div class="mobile">
        <MyHeaderMb/>
        <div class="banner-mb">
            <img src="../../assets/img/mb-eqbanner.png"/>
        </div>
        <div class="about-mb">
            <div class="banxin">
                <h2>关于我们</h2>
                <img class="aboutlogo" src="../../assets/img/eq-brand.png"/>
                <div class="about-text">Double Dragonflies(两只蜻蜓)是半亩智能旗下核心品牌，专注花园驱蚊领域。拥有国内一流的农业、园林及生态养护技术科研团队，专攻目前市场驱蚊剂型落后带来的环保和安全问题，研发真正意义上的绿色安全驱蚊药剂，达到国际一流水平。业内率先推出新一代庭院智能驱蚊系统，搭载纯天然植物精油，无味无刺激，我们以关爱为基点，创新场景化驱蚊理念有效解决人们在居家、办公和户外等不同场景下的驱蚊需求。</div>
                <div class="about-text">两只蜻蜓秉承“以智能科技引领未来无蚊生活”的品牌主张，不断探索行业新模式，洞察人们日益多样化的驱蚊诉求和消费习惯。我们的产品以智能便捷的使用体验、安全健康的全场景高效驱蚊及领先的技术优势，深受广大消费者喜爱。</div>
                <div class="about-text">两只蜻蜓的品牌理念：源于“安全、方便、有效”的客户诉求。蜻蜓作为大自然中蚊子的天敌，环境的检测专家，采用“Double Dragonflies(两只蜻蜓)”作为品牌形象，深刻地诠释着企业用心——坚持行业、消费者、大自然共生共赢。科技赋能，引领业态绿色升级，同时迭代生活理念，成为消费者心目中理想的健康驱蚊品牌。</div>
            </div>
        </div>
        <div class="love-mb">
            <div class="banxin">
                <h2>源自关爱</h2>
                <div class="love-box">
                    <div class="love-item" >
                        <img src="../../assets/img/mb-love1.png"/>
                        <div class="item-right">
                            <div class="love-title">引领未来无蚊生活</div>
                            <div class="love-text">半亩智能为了满足人们在不同场景下的驱蚊需求，创建了Double Dragonflies（两只蜻蜓）品牌。两只蜻蜓倡导品质生活理念，并坚持“以智能科技引领未来无蚊生活”的品牌主张，致力于为消费者提供真正绿色智能、有效的产品和服务。</div>
                        </div>
                    </div>
                    <div class="love-item" >
                        <div class="item-right" style="margin-left:1.25rem">
                            <div class="love-title">有态度的品牌</div>
                            <div class="love-text">两只靖挺不只是品牌，更是一种无蚊的生活新方式。“蜻蜓”，是大自然中蚊子的天敌，他们轻盈的身姿穿梭在水面、草丛、城市、以及户外。“两只”相互陪伴，关爱家人与健康；让我们在无蚊的空间中，变得有感情、有温度、有关怀。</div>
                        </div>
                        <img src="../../assets/img/mb-love2.png"/>
                    </div>
                    <div class="love-item" >
                        <img src="../../assets/img/mb-love3.png"/>
                        <div class="item-right">
                            <div class="love-title">“场景驱蚊”之风</div>
                            <div class="love-text">两只蜻蜓以健康驱蚊专家的形象立足于市场，以领先的技术及创新变革的理念为品牌核心优势，赢得消费者的认可和青睐。产品母婴级安全驱蚊剂，独具匠心的设计一经上市就受到了广泛好评，在驱蚊消费前沿市场刮起“场景驱蚊”之风。</div>
                        </div>
                    </div>
                    <div class="love-item" >
                        <div class="item-right" style="margin-left:1.25rem">
                            <div class="love-title">生活美学</div>
                            <div class="love-text">屋檐下，家人闲坐，灯火可亲；庭院里，孩子嬉戏，温馨欢乐；篝火时，亲友相聚，幸福满足......这些平凡的时刻才是生活中最温暖的部分。我们保持初心，让健康无蚊的生活美学索绕在每个人的心田，真正满足人们对品质生活的追求！</div>
                        </div>
                        <img src="../../assets/img/mb-love4.png"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="mosquitoes-mb">
            <div class="banxin">
                <h2>产品介绍——驱蚊剂</h2>
                <img src="../../assets/img/eq-product1.png">
                <div class="mosquitoes-box">
                    <div class="mosquitoes-title">驱蚊精油</div>
                    <div class="mosquitoes-text">严选世界各地十余种优质植物经天然基取，通过先进可靠的配方和工艺有机融合在一起，实现了技术性突破成将母婴级安全品质，不仅能像常规驱蚊精油喷涂于皮肤表面，保护皮肤避免蚊虫叮咬，用于室外环境下喷雾驱蚊效果亦十分理想。</div>
                </div>
                <div class="mosquitoes-box">
                    <div class="mosquitoes-title">除虫菊酯</div>
                    <div class="mosquitoes-text">世界卫生组织推荐用药，广泛应用于卫生害虫防治，是干燥菊属植物中的除虫菊花的提取物，也是一类常见的广谱杀虫剂，如生活中使用的蚊香、品牌杀虫剂、部分治疗猫醉及消除跳蚤的外用药等，都含有除虫菊脂的成分，其具有高效、安全、用药量小、易降解、代谢快等优势。</div>
                </div>
            </div>
        </div>
        <div class="product-mb">
            <div class="banxin">
                <h2>产品介绍——设备</h2>
                <div class="product-box">
                    <div class="product-item" v-for="item in product" :key="item.id">
                        <img :src="require(`@/assets/img/${item.url}`)"/>
                        <div class="product-title">{{ item.name }}</div>
                        <div class="product-text">最大喷头数：{{ item.Nozzle }}</div>
                        <div class="product-text">面积：{{ item.area }}</div>
                        <div class="product-text">功能：{{ item.function }}</div>
                        <div class="product-text" v-if="item.size">外箱尺寸：{{ item.size }}</div>
                    </div>
                    <div class="product-other">
                        <div class="other-title">更多可能</div>
                        <div class="other-text">敬请期待>></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="serive-mb">
            <div class="banxin">
                <h2>打造一站式服务体系</h2>
                <div class="serive-box">
                    <img src="../../assets/img/servic-mb.png"/>
                    <div class="box-right">
                        <div class="right">
                            <div class="right-title">解决方案定制化</div>
                            <div class="circle"></div>
                        </div>
                        
                        <div class="right-text">1、客户预约后，总部派发服务商上门测量，根据现场情况设计布局方案</div>
                        <div class="right-text">2、客户确认布局后，团队提供标准化系统安装方案及工程预算书</div>
                    </div>
                </div>
                <div class="serive-box">
                    <div class="box-right">
                        <div class="right">
                            <div class="right-title">施工管理标准化</div>
                            <div class="circle"></div>
                        </div>
                        <div class="right-text">1、专业工程师上门安装，从环境管理到系统布设，有步骤、有标准开展</div>
                        <div class="right-text">2、安装结束后，测试所有设备均能正常运行，交予客户完成验收交付</div>
                    </div>
                    <img src="../../assets/img/servic-mb2.png"/>
                </div>
                <div class="serive-box">
                    <img src="../../assets/img/servic-mb3.png"/>
                    <div class="box-right">
                        <div class="right">
                            <div class="right-title">客户服务关怀化</div>
                            <div class="circle"></div>
                        </div>
                        <div class="right-text">1、360°贴心服务，细分客户需求，成立专属服务小组 </div>
                        <div class="right-text">2、线上社群实时跟进与线下定期回访，全年上门维修确保客户体验感</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="classic-mb">
            <div class="banxin">
                <h2>经典案例</h2>
                <div class="classic-box">
                    <div class="title-box">
                        <div class="title">别墅应用案例</div>
                        <div class="little-title">/助力全家人的闲适时光</div>
                    </div>
                    <div class="img-box">
                        <img v-for="item in villa" :key="item.id" :src="require(`@/assets/img/${item.url}`)"/>
                    </div>
                </div>
                <div class="classic-box">
                    <div class="title-box">
                        <div class="title">校园应用案例</div>
                        <div class="little-title">/从容享受每一场户外体验</div>
                    </div>
                    <div class="img-box">
                        <img v-for="item in school" :key="item.id" :src="require(`@/assets/img/${item.url}`)"/>
                    </div>
                </div>
                <div class="classic-box">
                    <div class="title-box">
                        <div class="title">商家应用案例</div>
                        <div class="little-title">/助力经营者提升服务品质</div>
                    </div>
                    <div class="img-box">
                        <img v-for="item in sale" :key="item.id" :src="require(`@/assets/img/${item.url}`)"/>
                    </div>
                </div>
                <div class="classic-box">
                    <div class="title-box">
                        <div class="title">公园应用案例</div>
                        <div class="little-title">/打造更闲适的户外时光</div>
                    </div>
                    <div class="img-box">
                        <img v-for="item in parkmb" :key="item.id" :src="require(`@/assets/img/${item.url}`)"/>
                    </div>
                </div>
                <div class="classic-box">
                    <div class="title-box">
                        <div class="title">餐厅应用案例</div>
                        <div class="little-title">/打造更舒适的用餐环境</div>
                    </div>
                    <div class="img-box">
                        <img v-for="item in restaurantmb" :key="item.id" :src="require(`@/assets/img/${item.url}`)"/>
                    </div>
                </div>
                <div class="classic-box">
                    <div class="title-box">
                        <div class="title">企业应用案例</div>
                        <div class="little-title">/打造更专业的企业形象</div>
                    </div>
                    <div class="img-box">
                        <img v-for="item in businessmb" :key="item.id" :src="require(`@/assets/img/${item.url}`)"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="link-mb">
            <div class="banxin">
                <h2>联系我们</h2>
                <div class="link-box">
                    <div class="box-left">
                        <img src="../../assets/img/eq-link.png"/>
                        <div class="left-title">官方公众号</div>
                    </div>
                    <div class="box-right">
                        <div class="right-title">联系电话：</div>
                        <div class="right-phone">13510085068</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="banxin">
                <div class="left">
                    <p>深圳市两只蜻蜓智能科技有限公司</p>
                    <a  style="color:#ffffff;" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022128793号-1</a>
                </div>
                <img src="@/assets/mbimg/btlogo.png"/>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            love:[
                {id:1,url:'eq-love1.png',title:'引领未来无蚊生活',arcitle:'半亩智能为了满足人们在不同场景下的驱蚊需求，创建了Double Dragonflies（两只蜻蜓）品牌。两只蜻蜓倡导品质生活理念，并坚持“以智能科技引领未来无蚊生活”的品牌主张，致力于为消费者提供真正绿色智能、有效的产品和服务。'},
                {id:2,url:'eq-love2.png',title:'有态度的品牌',arcitle:'两只靖挺不只是品牌，更是一种无蚊的生活新方式。“蜻蜓”，是大自然中蚊子的天敌，他们轻盈的身姿穿梭在水面、草丛、城市、以及户外。“两只”相互陪伴，关爱家人与健康；让我们在无蚊的空间中，变得有感情、有温度、有关怀。'},
                {id:3,url:'eq-love3.png',title:'“场景驱蚊”之风',arcitle:'两只蜻蜓以健康驱蚊专家的形象立足于市场，以领先的技术及创新变革的理念为品牌核心优势，赢得消费者的认可和青睐。产品母婴级安全驱蚊剂，独具匠心的设计一经上市就受到了广泛好评，在驱蚊消费前沿市场刮起“场景驱蚊”之风。'},
                {id:4,url:'eq-love4.png',title:'生活美学',arcitle:'屋檐下，家人闲坐，灯火可亲；庭院里，孩子嬉戏，温馨欢乐；篝火时，亲友相聚，幸福满足......这些平凡的时刻才是生活中最温暖的部分。我们保持初心，让健康无蚊的生活美学索绕在每个人的心田，真正满足人们对品质生活的追求！'}
            ],
            lanyaoList:[
                {id:1,name:'研发费用年投入千万占营业额',count:'15%'},
                {id:2,name:'近百人研发团队 硕士以上占比',count:'60%'},
                {id:3,name:'产品更新迭代速度超同行',count:'10%'},
                {id:4,name:'自主研发技术占核心技术比重',count:'70%'},
            ],
            product:[
                {id:1,url:'eq-product2.png',name:'QT Mini',Nozzle:'30个适用',area:'50㎡-150㎡',function:'驱蚊',size:'550*550*200mm'}, 
                {id:2,url:'eq-product3.png',name:'QT 300',Nozzle:'30个适用',area:'100㎡-300㎡',function:'驱蚊/降温',size:'550*550*200mm'}, 
                {id:3,url:'eq-product4.png',name:'高压版',Nozzle:'定制面议',area:'300㎡-2000㎡',function:'驱蚊/造景/降温/喷灌',size:''} 
            ],
            asideList:[
                {id:1,url:'eq-aside1.png',tag:'天然原料',tag1:'全球直采'},
                {id:2,url:'eq-aside2.png',tag:'纯植物提取',tag1:'母婴级标准'},
                {id:3,url:'eq-aside3.png',tag:'专利防滴漏喷头',tag1:'微米级雾化'},
                {id:4,url:'eq-aside4.png',tag:'节能静音',tag1:'降温除尘'},
                {id:5,url:'eq-aside5.png',tag:'精选纳米纤维',tag1:'碳酸钢材'},
                {id:6,url:'eq-aside6.png',tag:'军工纳米涂层',tag1:'全机身绝缘防'}
            ],
            seriveList:[
                {id:1,url:'eq-serive1.png',tag:'解决方案定制化',text:'客户预约后，总部派发服务商上门测量，根 据现场情况设计布局方案',text1:'客户确认布局后，团队提供标准化系统安装 方案及工程预算书'},
                {id:2,url:'eq-serive2.png',tag:'施工管理标准化',text:'专业工程师上门安装，从环境管理到系统布设，有步骤、有标准开展 ',text1:'安装结束后，测试所有设备均能正常运行，交予客户完成验收交付'},
                {id:3,url:'eq-serive3.png',tag:'客户服务关怀化',text:'360°贴心服务，细分客户需求，成立专属服务小组',text1:'线上社群实时跟进与线下定期回访，全年上门维修确保客户体验感'},
            ],
            villa:[
                {id:1,url:'eq-villa1.png'},
                {id:2,url:'eq-villa2.png'},
                {id:3,url:'eq-villa3.png'},
                {id:4,url:'eq-villa4.png'},
                {id:5,url:'eq-villa5.png'},
                {id:6,url:'eq-villa6.png'}
            ],
            school:[
                {id:1,url:'eq-school1.png'},
                {id:2,url:'eq-school2.png'},
                {id:3,url:'eq-school3.png'},
            ],
            sale:[
                {id:1,url:'eq-sale1.png'},
                {id:2,url:'eq-sale2.png'},
                {id:3,url:'eq-sale3.png'},
            ],
            parkList:[
                {id:1,url:'eq-park1.png'},
                {id:2,url:'eq-park2.png'},
                {id:3,url:'eq-park3.png'},
                {id:4,url:'eq-park4.png'}
            ],
            parkmb:[
                {id:1,url:'eq-park1.png'},
                {id:2,url:'eq-park2.png'},
                {id:3,url:'eq-park3.png'},
            ],
            restaurant:[
                {id:1,url:'eq-restaurant1.png'},
                {id:2,url:'eq-restaurant2.png'},
                {id:3,url:'eq-restaurant3.png'},
                {id:4,url:'eq-restaurant4.png'}
            ],
            restaurantmb:[
                {id:1,url:'eq-restaurant1.png'},
                {id:2,url:'eq-restaurant2.png'},
                {id:3,url:'eq-restaurant3.png'},
            ],
            business:[
                {id:1,url:'eq-business1.png'},
                {id:2,url:'eq-business2.png'},
                {id:3,url:'eq-business3.png'},
                {id:4,url:'eq-business4.png'}
            ],
            businessmb:[
                {id:1,url:'eq-business1.png'},
                {id:2,url:'eq-business2.png'},
                {id:3,url:'eq-business3.png'}
            ]

        }
    }
}
</script>

<style lang='scss' scoped>
.pc{
    .banner-pc{
        img{
            width: 100%;
            height: 34.375rem;
        }
    }

    .brand-pc{
        width: 100%;
        //height: 37.0625rem;
        background: #ffffff;
        .banxin{
            width: 62.5%;
            height: 100%;
            margin: 0 auto;
            box-sizing: border-box;
            padding: 6.375rem 0 7.875rem 0;
            .title-box{
                margin-bottom: 2.25rem;
                .chinese-title{
                    font-size: 1.875rem;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: .625rem;
                }
                .english-title{
                    font-size: 1rem;
                    font-family: MiSans;
                    font-weight: 400;
                    color: #63C0AB;
                }
            }

            .content{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                image{
                    width: 23.08%;
                    height: 17%;
                }
                .arctic{
                    width: 71.5%;
                    font-size: 1rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 1.625rem;
                }
            }
        }
    }

    .lovebox-pc{
        width: 100%;
        margin-bottom: 6.25rem;
        background: #ffffff;
        .banxin{
            width: 61.5%;
            height: 100%;
            margin: 0 auto;
            .title-box{
                margin-bottom: 3.75rem;
                .chinese-title{
                    font-size: 1.875rem;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: .625rem;
                }
                .english-title{
                    font-size: 1rem;
                    font-family: MiSans;
                    font-weight: 400;
                    color: #63C0AB;
                }
            }
            .content-box{
                width: 100%;
                min-height: 27.125rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .item-box{
                    width: 23.91%;
                    height: 100%;
                    background: #FFFFFF;
                    box-shadow: 0rem 0rem .75rem .25rem rgba(211,211,211,0.45);
                    border-radius: .875rem;
                    padding-bottom:1.875rem ;
                    img{
                        width: 100%;
                        min-height: 51.15%;
                        margin-bottom: 1.25rem;
                    }
                    .item-bottom{
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0rem 1.25rem;
                        .item-title{
                            font-size: 1.375rem;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            line-height: 1.625rem;
                            margin-bottom: 1.1875rem;
                        }
                        .item-arcitle{
                            font-size: .875rem;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #999999;
                            line-height: 1.125rem;
                        }
                    }
                }
            }
        }
    }

    .lanyao-pc{
        width: 100%;
        height: 21.9375rem;
        background: url('../../assets/img/eq-lanyao.png');
        margin-bottom: 6.25rem;
        .banxin{
            width: 62.5%;
            height: 100%;
            margin: 0 auto;
            ul{
                height: 31.7%;
                width: 14%;
                li{
                    width: 100%;
                    &:nth-child(1){
                        font-size: 1.125rem;
                        line-height: 1.625rem;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-bottom: 1.375rem;
                        text-align: center;
                    }
                    &:nth-child(2){
                        font-size: 3.75rem;
                        font-weight: bold;
                        color: #FFFFFF;
                        text-align: center;
                    }

                }
            }
        }
    }

    .productbox-pc{
        width: 100%;
        background: #ffffff;
        margin-bottom: 6.25rem;
        .banxin{
            width: 62.5%;
            margin: 0 auto;
            .title-box{
                margin-bottom: 2.25rem;
                .chinese-title{
                    font-size: 1.875rem;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: .625rem;
                }
                .english-title{
                    font-size: 1rem;
                    font-family: MiSans;
                    font-weight: 400;
                    color: #63C0AB;
                }
            }
            .box-top{
                margin-bottom: 6.125rem;
                .content{
                    padding-left: 7.5625rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img{
                        width: 29.7%;
                        height: 19rem;
                    }
                    .right{
                        width: 50.75%;
                        .right-box{
                            width: 100%;
                            .title{
                                font-size: 1.375rem;
                                font-family: Microsoft YaHei;
                                font-weight: bold;
                                color: #333333;
                                line-height: 1.625rem;
                            }
                            .articl{
                                font-size: 1rem;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #999999;
                                line-height: 1.625rem;
                            }
                        }
                    }
                }
            }
            .box-bottom{
                width: 100%;
                .content{
                    width: 100%;
                    padding-left: 1.6875rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .content-left{
                        width: 80.3%;
                        display: flex;
                        justify-content:space-between ;
                        align-items: center;
                        .left-box{
                            width: 21.7%;
                            img{
                                width: 100%;
                                height: 12.25rem;
                                margin-bottom: 1.875rem;
                            }
                            .name{
                                font-size: 1.375rem;
                                font-family: Microsoft YaHei;
                                font-weight: bold;
                                color: #333333;
                                line-height: 1.625rem;
                                margin-bottom: 2.125rem;
                            }
                            .pro-intro{
                                .intro-item{
                                    font-size: 1rem;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #999999;
                                    line-height: 1.5rem;
                                }
                            }
                        }
                    }
                    .content-right{
                        width: 9%;
                        .right-title{
                            font-size: 1.375rem;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #000000;
                            line-height: 1.625rem;
                            margin-bottom: 1rem;
                        }
                        .right-other{
                            font-size: 1.125rem;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #63C0AB;
                            line-height: 1.625rem;
                        }
                    }
                }
            }
        }
    }

    .aside-pc{
        width: 100%;
        height: 30rem;
        background: url('../../assets/img/eq-aside.png');
        margin-bottom: 6.375rem;
        .banxin{
            width: 62.5%;
            height: 100%;
            margin: 0 auto;
            box-sizing: border-box;
            padding: 6.3125rem 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .aside-item{
                width: 13.66%;
                height: 100%;
                background: #64C0AB !important;
                opacity: 0.5;
                border-radius: .875rem;
                z-index: 2;
                box-sizing: border-box;
                padding: 3.5625rem 0 4.4375rem 0;
                .top{
                    width: 4rem;
                    height: 4rem;
                    border: .125rem solid #FFFFFF;
                    border-radius: 50%;
                    margin: 0 auto;
                    margin-bottom: 2.625rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: 2.75rem;
                        height: 2.75rem;
                    }
                }
                .tag{
                    font-size: 1.125rem;
                    font-family: MiSans;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 1.625rem;
                    text-align: center;
                }
                .tag1{
                    font-size: 1.125rem;
                    font-family: MiSans;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 1.625rem;
                    text-align: center;
                }
            }
        }
    }

    .serive-pc{
        width: 100%;
        background: #ffffff;
        margin-bottom: 6.375rem;
        .banxin{
            width: 62.5%;
            margin: 0 auto;
            .title-box{
                margin-bottom: 2.25rem;
                .chinese-title{
                    font-size: 1.875rem;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: .625rem;
                }
                .english-title{
                    font-size: 1rem;
                    font-family: MiSans;
                    font-weight: 400;
                    color: #63C0AB;
                }
            }
            .content{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .serive-item{
                    width: 32.92%;
                    position: relative;
                    img{
                        width: 100%;
                        height: 24.6875rem;
                        margin-bottom: .9375rem;
                        
                    }
                    .tag{
                        font-size: 1.375rem;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #FFFFFF;
                        line-height: 1.625rem;
                        z-index: 2;
                        position: absolute;
                        top: 60%;
                        left: 30%;
                    }
                    .text{
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0 1.875rem;
                        font-size: 1rem;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #999999;
                        line-height: 1.625rem;
                    }
                    .text1{
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0 1.875rem;
                        font-size: 1rem;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #999999;
                        line-height: 1.625rem;
                    }
                }
            }
        }
    }

    .classic-pc{
        width: 100%;
        background: #ffffff;
        .banxin{
            width: 62.5%;
            margin: 0 auto;
            .title-box{
                margin-bottom: 2.25rem;
                .chinese-title{
                    font-size: 1.875rem;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: .625rem;
                }
                .english-title{
                    font-size: 1rem;
                    font-family: MiSans;
                    font-weight: 400;
                    color: #63C0AB;
                }
            }
            .classic-top{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 2.5rem;
                .littleTitle-box{
                    margin-bottom: 2.4375rem;
                    .chinese-title{
                        font-size: 1.375rem;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 1.625rem;
                        margin-bottom: 1rem;
                    }
                    .fu-title{
                        font-size: 1rem;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #999999;
                        line-height: 1.625rem;
                    }
                }
                .top-left{
                    width: 48.5%;
                    .content{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        img{
                            width: 49.4%;
                            height: 12.1875rem;
                            margin-bottom: .375rem;
                        }
                    }
                }
                .top-right{
                    width: 48.5%;
                    .school-box{
                        width: 100%;
                        margin-bottom: 2.6rem;
                        .content{
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .content-box{
                                width: 49.4%;
                                img{
                                    width: 100%;
                                    height: 12.1875rem;
                                }
                                .img-text{
                                    font-size: 1rem;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #999999;
                                    line-height: 1.625rem;
                                    text-align: center;
                                }
                            }
                        }
                    }



                }
            }
            .classic-middle{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 2.5rem;
                .littleTitle-box{
                    margin-bottom: 2.4375rem;
                    .chinese-title{
                        font-size: 1.375rem;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 1.625rem;
                        margin-bottom: 1rem;
                    }
                    .fu-title{
                        font-size: 1rem;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #999999;
                        line-height: 1.625rem;
                    }
                }
                .middle-left{
                    width: 48.5%;
                    .content{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        img{
                            width: 49.4%;
                            height: 12.1875rem;
                            margin-bottom: .375rem;
                        }
                    }
                }

            }
            .classic-bottom{
                width: 100%;
                margin-bottom: 5.625rem;
                .littleTitle-box{
                    margin-bottom: 2.4375rem;
                    .chinese-title{
                        font-size: 1.375rem;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 1.625rem;
                        margin-bottom: 1rem;
                    }
                    .fu-title{
                        font-size: 1rem;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #999999;
                        line-height: 1.625rem;
                    }
                }
                .content{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    img{
                        width: 23.92%;
                        height: 12.1875rem;
                    }
                }
            }
        }
    }

    .footer-pc{
        width: 100%;
        height:23.75rem;
        background:#276849;;
        .banxin{
            width: 62.5%;
            height: 100%;
            margin:0 auto;
            box-sizing: border-box;
            padding: 5.3125rem 0 2.4375rem 0;
            .top{
                height: 10.3125rem;
                margin-bottom: 4.6875rem;
                img{

                }
                .nav{
                    height: 10.3125rem;
                    width: 6.1875rem;
                    li{
                        margin-bottom: 1.875rem;
                        font-size: 14px;
                        font-weight: 400;
                        color: #FFFFFF;
                        &:nth-child(1){
                            font-size: 18px;
                            font-weight: bold;
                            color: #FFFFFF;
                            position: relative;
                            &::after{
                                content: '';
                                width: 46px;
                                height: 2px;
                                background:#FFBF59;
                                position: absolute;
                                top: 1.875rem;
                                left: 0;
                            }
                        }
                    }
                }
                .link{
                    height: 10.3125rem;
                    width: 9.375rem;
                    li{
                        margin-bottom: 1.875rem;
                        font-size: 14px;
                        font-weight: 400;
                        color: #FFFFFF;
                        &:nth-child(1){
                            font-size: 18px;
                            font-weight: bold;
                            color: #FFFFFF;
                            position: relative;
                            &::after{
                                content: '';
                                width: 46px;
                                height: 2px;
                                background:#FFBF59;
                                position: absolute;
                                top: 1.875rem;
                                left: 0;
                            }
                        }
                    }
                }
                .concern{
                    height: 10.3125rem;
                    width: 14.25rem;
                    p{
                        font-size: 18px;
                        font-weight: bold;
                        color: #FFFFFF;
                        position: relative;
                        margin-bottom:1.875rem;
                            &::after{
                                content: '';
                                width: 46px;
                                height: 2px;
                                background:#FFBF59;
                                position: absolute;
                                top: 1.875rem;
                                left: 0;
                            }
                    }
                    .concern-bottom{
                        ul{
                            li{
                                font-size: 12px;
                                font-weight: 300;
                                color: #FFFFFF;
                                margin-top: .75rem;
                            }
                        }
                    }
                }
            }

            .bottom{
                width: 100%;
                font-size: 14px;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
            }
        }
    }
}

.mobile{
    .banner-mb{
        width: 100%;
        margin-bottom:1.875rem;
        img{
            width: 100%;
            max-height: 50rem;
        }
    }
    .about-mb{
        width: 100%;
        background: #ffffff;
        margin-bottom: .625rem;
        .banxin{
            width: 91.33%;
            //height: 57.5625rem;
            max-height: 57.5625rem;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            h2{
                    height: 3.7%;
                    width: 100%;
                    font-size:calc(100vw * 36/ 750); 
                    font-weight: bold;
                    color: #333333;
                    position: relative;
                    box-sizing: border-box;
                    padding-left: 1.5rem;
                    display: flex;
                    align-items:center ;
                    position: relative;
                    margin-bottom: 4rem;
                    &::after{
                        content: '';
                        width: 1.02%;
                        height: 80%;
                        background: #63C0AB;
                        position: absolute;
                        left: 0;
                        top: 10%;
                    }
            }
            .aboutlogo{
                width: 49.87%;
                height: 14.88%;
                margin-bottom: 5.43%;
            }
            .about-text{
                font-size: calc(100vw * 24/ 750);
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: calc(100vw * 34/ 750);
                margin-bottom: 5.43%;
            }
        }
    }
    .love-mb{
        width: 100%;
        background: #ffffff;
        margin-bottom: 2.3125rem;
        .banxin{
            width: 91.33%;
            //height: 84.1875rem;
            max-height: 84.1875rem;
            margin: 0 auto;
            h2{
                    height: 2.67%;
                    width: 100%;
                    font-size:calc(100vw * 36/ 750); 
                    font-weight: bold;
                    color: #333333;
                    position: relative;
                    box-sizing: border-box;
                    padding-left: 1.5rem;
                    display: flex;
                    align-items:center ;
                    position: relative;
                    margin-bottom: 2.375rem;
                    &::after{
                        content: '';
                        width: 1.02%;
                        height: 80%;
                        background: #63C0AB;
                        position: absolute;
                        left: 0;
                        top: 10%;
                    }
            }
            .love-box{
                width: 100%;
                height: 94.58%;
                .love-item{
                    width: 100%;
                    height: 22.42%;
                    max-height: 18.875rem;
                    margin-bottom: 1.375rem;
                    background: #FFFFFF;
                    box-shadow: 0rem 0rem .75rem 0rem rgba(99,192,171,0.23);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    img{
                        height: 100%;
                        width: 41.18%;
                    }
                    .item-right{
                        width: 51.91%;
                        height: 90.5%;
                        padding: 6% 0;
                        .love-title{
                            font-size: calc(100vw * 28/ 750);
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            line-height: calc(100vw * 34/ 750);
                            margin-bottom: 6.56%;
                            
                        }
                        .love-text{
                            font-size:  calc(100vw * 22/ 750);
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #999999;
                            line-height: calc(100vw * 30/ 750);
                        }
                    }
                }
            }
        }
    }
    .mosquitoes-mb{
        width: 100%;
        background: #ffffff;
        margin-bottom: 1.875rem;
        .banxin{
            width: 91.33%;
            //max-height: 30.0625rem;
            margin: 0 auto;
            display: flex;
            flex-flow: column;
            align-items: center;
            h2{
                    height: 2.67%;
                    width: 100%;
                    font-size:calc(100vw * 36/ 750); 
                    font-weight: bold;
                    color: #333333;
                    position: relative;
                    box-sizing: border-box;
                    padding-left: 1.5rem;
                    display: flex;
                    align-items:center ;
                    position: relative;
                    margin-bottom: 12.47%;
                    &::after{
                        content: '';
                        width: 1.02%;
                        height: 80%;
                        background: #63C0AB;
                        position: absolute;
                        left: 0;
                        top: 10%;
                    }
            }
            img{
                width: 52.35%;
                height: 63.2%;
                margin-bottom: 8.73%;
            }
            .mosquitoes-box{
                width: 100%;
                margin-bottom: 6.03%;
                .mosquitoes-title{
                    font-size: calc(100vw * 28/ 750);
                    font-weight: bold;
                    color: #333333;
                    line-height: calc(100vw * 34/ 750);
                    margin-bottom: 6.03%;
                }
                .mosquitoes-text{
                    font-size: calc(100vw * 22/ 750);
                    font-weight: 400;
                    color: #999999;
                    line-height: calc(100vw * 30/ 750);
                }
            }
        }
    }
    .product-mb{
        width: 100%;
        background: #ffffff;
        margin-bottom: 1.875rem;
        .banxin{
            width: 91.33%;
            margin: 0 auto;
            h2{
                    height: 3.72%;
                    width: 100%;
                    font-size:calc(100vw * 36/ 750); 
                    font-weight: bold;
                    color: #333333;
                    position: relative;
                    box-sizing: border-box;
                    padding-left: 1.5rem;
                    display: flex;
                    align-items:center ;
                    position: relative;
                    margin-bottom: 4rem;
                    &::after{
                        content: '';
                        width: 1.02%;
                        height: 80%;
                        background: #63C0AB;
                        position: absolute;
                        left: 0;
                        top: 10%;
                    }
            }
            .product-box{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                .product-item{
                    width: 45%;
                    margin-bottom: 3.93%;
                    display: flex;
                    align-items: center;
                    flex-flow: column;
                    img{
                        width: 77.7%;
                        height: 20.08%;
                        margin-bottom: 4.3%;
                    }
                    .product-title{
                        font-size: calc(100vw * 28/ 750);
                        font-weight: bold;
                        color: #333333;
                        line-height: calc(100vw * 34/ 750);
                        margin-bottom: 3.28%;
                    }
                    .product-text{
                        font-size: calc(100vw * 22/ 750);
                        font-weight: 400;
                        color: #999999;
                        line-height: calc(100vw * 30/ 750);
                    }
                }
                .product-other{
                    width: 45%;
                    .other-title{
                        font-size: calc(100vw * 28/ 750);
                        font-weight: bold;
                        color: #000000;
                        margin-bottom: .75rem;
                        text-align: center;
                    }
                    .other-text{
                        font-size: calc(100vw * 22/ 750);
                        font-weight: 400;
                        color: #63C0AB;
                        line-height: calc(100vw * 26/ 750);
                        text-align: center;
                    }
                }
            }
        }
    }
    .serive-mb{
        width: 100%;
        background: #ffffff;
        margin-bottom: 1.25rem;
        .banxin{
            width: 91.33%;
            margin: 0 auto;
            h2{
                    height: 3.72%;
                    width: 100%;
                    font-size:calc(100vw * 36/ 750); 
                    font-weight: bold;
                    color: #333333;
                    position: relative;
                    box-sizing: border-box;
                    padding-left: 1.5rem;
                    display: flex;
                    align-items:center ;
                    position: relative;
                    margin-bottom: 2.4375rem;
                    &::after{
                        content: '';
                        width: 1.02%;
                        height: 80%;
                        background: #63C0AB;
                        position: absolute;
                        left: 0;
                        top: 10%;
                    }
            }
            .serive-box{
                width: 100%;
                height: 27.81%;
                margin-bottom: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                img{
                    width: 34.67%;
                    height: 100%;
                }
                .box-right{
                    width: 49.73%;
                    .right{
                        display: flex;
                        align-items: center;
                        .right-title{
                            font-size:calc(100vw * 28/ 750) ;
                            font-weight: bold;
                            color: #333333;
                            line-height: calc(100vw * 34/ 750);
                            margin-bottom: 2.14%;
                            z-index: 2;
                        }

                        .circle {
                            width: 1rem;
                            height: 1rem;
                            border-radius: 50%;
                            background-color: #63C0AB;
                            margin-left: -0.5rem;
                            margin-bottom:1rem ;
                        }
                        .circle::after {
                            content: '';
                            position: relative;
                            top: .25rem;
                            left: .25rem;
                            display: block;
                            width: .5rem;
                            height: .5rem;
                            border-radius: 50%;
                            background-color: #fff;
                        }

                    }
                    
                    .right-text{
                        font-size: calc(100vw * 22/ 750);
                        font-weight: 400;
                        color: #999999;
                        line-height: calc(100vw * 30/ 750);
                    }
                }
            }
        }
    }
    .classic-mb{
        width: 100%;
        background: #ffffff;
        margin-bottom: 1.25rem;
        .banxin{
            width: 91.33%;
            margin: 0 auto;
            h2{
                    height: 3.72%;
                    width: 100%;
                    font-size:calc(100vw * 36/ 750); 
                    font-weight: bold;
                    color: #333333;
                    position: relative;
                    box-sizing: border-box;
                    padding-left: 1.5rem;
                    display: flex;
                    align-items:center ;
                    position: relative;
                    margin-bottom: 2.4375rem;
                    &::after{
                        content: '';
                        width: 1.02%;
                        height: 80%;
                        background: #63C0AB;
                        position: absolute;
                        left: 0;
                        top: 10%;
                    }
            }
            .classic-box{
                margin-bottom: 3%;
                .title-box{
                    display: flex;
                    align-items:center;
                    margin-bottom: 2%;
                    .title{
                        font-size: calc(100vw * 28/ 750);
                        font-weight: bold;
                        color: #333333;
                    }
                    .little-title{
                        font-size: calc(100vw * 18/ 750);
                        font-weight: 400;
                        color: #999999;
                        line-height: calc(100vw * 24/ 750);
                        margin-left: .625rem;
                        margin-top: .25rem;
                    }
                }
                .img-box{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    img{
                        width: 32.35%;
                        margin-bottom: 1%;
                    }
                }
            }
        }
    }
    .link-mb{
        width: 100%;
        background: #ffffff;
        margin-bottom: 10%;
        .banxin{
            width: 91.33%;
            margin: 0 auto;
            h2{
                    height: 3.72%;
                    width: 100%;
                    font-size:calc(100vw * 36/ 750); 
                    font-weight: bold;
                    color: #333333;
                    position: relative;
                    box-sizing: border-box;
                    padding-left: 1.5rem;
                    display: flex;
                    align-items:center ;
                    position: relative;
                    margin-bottom: 2.4375rem;
                    &::after{
                        content: '';
                        width: 1.02%;
                        height: 80%;
                        background: #63C0AB;
                        position: absolute;
                        left: 0;
                        top: 10%;
                    }
            }
            .link-box{
                display: flex;
                align-items: center;
                justify-content: center;
                .box-left{
                    //width: 30%;
                    margin-right: 11.62%;
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    img{
                        height: 62.03%;
                        width: 26.91%;
                        margin-bottom: 4.41%;
                    }
                    .left-title{
                        font-size: calc(100vw * 22/ 750);
                        font-weight: 400;
                        color: #333333;
                        line-height: calc(100vw * 28/ 750);
                    }
                }
                .box-right{
                    .right-title{
                        font-size: calc(100vw * 24/ 750);
                        font-weight: bold;
                        color: #333333;
                        line-height: calc(100vw * 28/ 750);
                    }
                    .right-phone{
                        font-size: calc(100vw * 24/ 750);
                        font-weight: bold;
                        color: #333333;
                        line-height: calc(100vw * 28/ 750);
                    }
                }
            }
        }
    }
    .footer{
            width: 100%;
            max-height: 6.25rem;
            background: #276849;
            .banxin{
                width: 91.33%;
                height: 100%;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding-top: 2.8%;
                padding-bottom:2.8%;
                .left{
                    width: 69.4%;
                    height: 44%;
                    font-size: calc(100vw * 16/750);
                    font-weight: 400;
                    color: #FFFFFF;
                }
                img{
                    width: 40.6%;
                    height: 44%;
                }
            }
    }
}
</style>