<template>
    <div class='Business'>
        <div class="top">
            <img src="../../assets/img/count.png"/>
            <h2>重庆市大盘数据</h2>
        </div>
        <div class="bottom flex">
            <div class="curcal ">
                <div id="main" :style="{width:'500px',height:'486px'}"></div>
            </div>
            <div class="title">
                <ul v-for="(item) in titleList" :key="item.id">
                    <li class="color-f">{{item.title}}</li>
                    <li class="color-00FFDE">{{item.num}}</li>
                </ul>
            </div>
            
        </div>
    </div>
    </template>
    
    
    <script>
    import * as echarts from 'echarts';
    import cqmap from '../../../public/cqmap';
    export default{
        name:'MyRtData',
        data(){
            return{
                titleList:[
                    {id:1,title:'花园数量(户)',num:18860},
                    {id:2,title:'订购服务比例',num:'86%'},
                    {id:3,title:'总工单(笔)',num:2361},
                    {id:4,title:'服务销售额(元)',num:115482},
                ]
            }
        },
    
        mounted(){
           this.getArea();
        },
    
        methods:{
            getArea(){
                let myChart=echarts.init(document.getElementById("main"))
                this.$echarts.registerMap('cqmap',cqmap);
                var option = {
    
                        // 关联数据
                        geo:{
                            type:'map',
                            map:'cqmap',
                            aspectScale:0.9,
                            zoom: 1.2, 
                            roam:false, // 允许能对地图进行缩放、拖动 的操作
                             itemStyle: {
                                normal: { 
                                    areaColor: {
                                        type:'radial',
                                        x: 0.5,
                                        y: 0.5,
                                        r: 1,
                                        colorStops: [
                                        {
                                            offset: 0,
                                            color: "rgba(0, 255, 222, 0.3)", 
                                        },
                                        {
                                            offset: 1,
                                            color: "#217f8f", 
                                        },
                                        ],
                                        globalCoord: false, // 缺省为 false
                                    },
                                    borderWidth: 1, // 边框大小
                                    borderColor:'#fff',
                                    shadowColor: "#fff", // 阴影颜色
                                    // shadowBlur:5,
                                },
                                emphasis:{
                                    areaColor:"#36fcde",
                                }

                            }
                        },
                        series:[{
                            type:'scatter',
                            coordinateSystem: 'geo', // 该系列使用的坐标系
                            symbolSize: [20,26], // 标记大小
                            showAllSymbol:true,
                            symbol:'path://M895.616384 347.812188q0 10.22977-0.511489 19.436563t-1.534466 19.436563q-9.206793 84.907093-37.338661 163.164835t-71.096903 150.377622-99.228771 138.613387-121.734266 127.872128q-9.206793 11.252747-23.528472 11.252747-15.344655 0-24.551449-11.252747-65.470529-61.378621-122.245754-128.895105t-100.251748-141.170829-71.608392-152.935065-36.315684-165.210789q0-8.183816-0.511489-15.344655t-0.511489-15.344655q0-71.608392 28.131868-135.032967t76.211788-110.481518 113.038961-74.677323 138.613387-27.62038 138.101898 27.62038 112.527473 74.677323 76.211788 110.481518 28.131868 135.032967zM540.643357 507.396603q33.758242 0 63.424575-12.787213t51.66034-34.26973 34.781219-50.637363 12.787213-61.89011-12.787213-61.89011-34.781219-50.637363-51.66034-34.26973-63.424575-12.787213-63.424575 12.787213-52.171828 34.26973-35.292707 50.637363-12.787213 61.89011 12.787213 61.89011 35.292707 50.637363 52.171828 34.26973 63.424575 12.787213z',
                            zlevel: 2, // 这个要比地图的大，就是geo里面的zlevel
                            label: {
                                show: false
                            },
                            itemStyle:{
                                color:'rgba(255, 168, 0, 1)',
                            },
                            data:[
                                {name:'hahaha',value:[109.40093,31.018551]},
                                {name:'222222',value:[108.69698,30.93063]},
                                {name:'222223',value:[108.37288332031251,30.798603539987337]},
                                {name:'222224',value:[109.56764650390626,31.391286078692804]},
                                {name:'222225',value:[108.39485597656251,31.15888766831695]},
                                {name:'222226',value:[106.57439,29.60661]},
                                {name:'222227',value:[106.25936,29.29014]},
                                {name:'222228',value:[107.25870064453122,229.758438554072356]},
                                {name:'222229',value:[107.38710335449215,29.7125287597019]},
                                {name:'222210',value:[107.26282051757809,29.912112834236492]},
                                {name:'222211',value:[1106.65583588378905,29.70664899294603]},
                                {name:'222212',value:[106.27679,29.97288]},
                                {name:'222213',value:[106.05638,29.84475]},
                                {name:'222214',value:[105.83952,30.19054]},
                                {name:'222215',value:[107.73085,29.86352]},
                                {name:'222216',value:[107.75993,29.32543]},
                                {name:'222217',value:[107.09896,29.15788]},
                                {name:'222218',value:[106.92852,28.96463]},
                                {name:'222219',value:[107.33515,30.3268]},
                                {name:'222220',value:[107.80235,30.67373]},
                                {name:'222221',value:[108.03767,30.30026]},
                                {name:'222222',value:[108.76778,28.84126]},
                                {name:'222223',value:[108.16555,29.29376]},
                                {name:'222224',value:[105.92709,29.356]},

                            ]

                        }]

                }
    
                myChart.setOption(option);
    
                window.addEventListener('resize',()=>{
                    myChart.resize();
                });
            }
        }
    }
    </script>
    
    
    
    <style lang='scss' scoped>
        .Business{
            width: 42.5625rem;
            height: 27.875rem;
            box-sizing: border-box;
            padding:1.4375rem 0 0 1.75rem;
            .top{
                img{
                width:1.125rem;
                height: 1.0625rem;
                    margin-right: .625rem;
                }
                h2{
                    display: inline-block;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }
            
            .bottom{
                width: 40.875rem;
                height: 23.75rem;
                .title{
                    width: 11.5rem;
                    height: 21.875rem;
                    box-sizing: border-box;
                    padding-top:2.25rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    ul{
                        li{
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            margin-bottom: .5625rem;
                            &:nth-child(2){
                                width: 6.125rem;
                            }
                        }
                    }
                }
                .curcal{
                    width: 28.125rem;
                    height: 24.125rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
</style>