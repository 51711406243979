<!-- 服务预警组件 -->
<template>
<div class='Warning'>
    <div class="top">
        <img src="../../assets/img/warn.png"/>
        <h2>服务预警</h2>
    </div>

    <div class="bottom">
        <div class="tableTop">
          <ul class="title">
            <li>ID</li>
            <li>事件名称</li>
            <li>时间</li>
            <li>预警级别</li>
          </ul>
          <el-divider></el-divider>
        </div>
            <div class="concent">
               <vue-seamless-scroll :data="listData" class="warp" :class-option="optionSetting">
                <ul class="item">
                  <li v-for="(item) in listData" :key="item.id">
                    <span class="id width-50">{{item.id}}</span>
                    <span class="serve width-146 center">{{item.serve}}</span>
                    <span class="time width-176">{{item.date}}  {{item.time}}</span>
                    <span class="serve width-73">
                      <img src="../../assets/img/warn-icon.png" v-for="(i,cindex) in item.grade" :key="cindex" class="m-r-6"/>
                    </span>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
      </div>
</div>

</template>


<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
export default{
    name:'MyWarning',
    components: {
        vueSeamlessScroll
    },
    data(){
        return{
            listData: [{
                id:1521,
                serve: '土壤温度超标',
                date: '2022/10/20',
                time:'14:22',
                grade:3,
                
            }, {
                id:2774,
                serve: '土壤氮磷钾超标',
                date: '2022/10/20',
                time:'14:55',
                grade:3,
            }, {
                id:1422,
                serve: '红火蚁密度超标',
                date: '2022/10/20',
                time:'15:20',
                grade:3,
            }, {
                id:1423,
                serve: '蚁虫密度四级',
                date: '2022/10/20',
                time:'15:20',
                grade:2,
            }, {
                id:1520,
                serve: '土壤温度超标',
                date: '2022/10/20',
                time:'14:22',
                grade:2,
            }, {
                id:2773,
                serve: '土壤氮磷钾超标',
                date: '2022/10/20',
                time:'14:55',
                grade:1,
            }, {
                id:2777,
                serve: '土壤氮磷钾超标',
                date: '2022/10/20',
                time: '14:55',
                grade:1,
            }
          ]

        }
    },
    computed: {
      optionSetting () {
        return {
            step: 0.3, // 数值越大速度滚动越快
            limitMoveNum: this.listData.length, // 开始无缝滚动的数据量 this.dataList.length
            hoverStop: true, // 是否开启鼠标悬停stop
            direction: 1, // 0向下 1向上 2向左 3向右
            // autoPlay: false,
            openWatch: true, // 开启数据实时监控刷新dom
            singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
            singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
            waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
        }
        }
  },  
}
</script>


<style lang='scss' scoped>
.Warning{
    height: 27.125rem;
    width: 42.375rem;
    background: url(../../assets/img/warning.png) no-repeat;
    box-sizing: border-box;
    padding: 1.375rem 2.1875rem 1.5625rem 1.75rem;
    .top{
        margin-bottom: 2.5rem;
        img{
        height: 1.1875rem;
        width: 1.1875rem;
        margin-right: .75rem;
        }
        h2{
            display: inline-block;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
        }
    }
    .bottom{
      .tableTop{
        .title{
          width: 38.6875rem;
          height: 1.4375rem;
          box-sizing: border-box;
          padding: 0 1.625rem 0 2.375rem;
          display: flex;
          justify-content: space-between;
          margin-bottom: .8125rem;
          li{
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FEFFFF;
          }
        }
      }

      .warp {
        height: 15.625rem;
        width: 38.0625rem;
        margin: 0 auto;
        overflow: hidden;
        ul {
         
          list-style: none;
          box-sizing: border-box;
          padding: 0 1.9375rem 0  1.0625rem;
          margin: 0 auto;
          
          li,
          
          a {
              display: inline-block;
              height: 2.5rem;
              line-height: 40px;
              display: flex;
              justify-content: space-between;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #FEFFFF;
              span{
                  img{
                    &:nth-child(3){
                      margin-right: 0;
                    }
                  }
              }
              
            }
        }
      }

    }
    
}
</style>